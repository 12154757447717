import React, { useEffect, useRef, useState } from 'react';
import styles from "./styles.module.css";
import IntroImg from "./images/intro-img.png";
import LoginImg from "./images/login.png";
import LoginOtp from "./images/otp-login.png";
import EmailLogin from "./images/email-login.png";
import MonthlyPlan from "./images/monthly-plan.png";
import YearlyPlan from "./images/yearly-plan.png";
import MockIcon from "./images/mock.png";
import MockRequestImg from "./images/mock-request.png";
import MockDetails from "./images/mock-details.png";
import CopyIcon from "./images/copy-icon.png";
import SaveChange from "./images/save-change.png";
import ActualTab from "./images/actual-tab.png";
import SendClickImg from "./images/send-click.png";
import SendClickMockImg from "./images/send-mock.png";
import MockResponse from "./images/mock-response.png";
import ActualResponse from "./images/actual-response.png";
import ShareIcon from "./images/share-icon.png";
import EnterEmail from "./images/enter-email.png";
import ChangAccess from "./images/change-access.png";
import ExportImg from "./images/export.png";
import ImportImg from "./images/import.png";
import EnvCreate from "./images/env-create.png";
import VariableCreate from "./images/variable-create.png";
import EnvSave from "./images/env-save.png";
import HistoryImg from "./images/history-img.png";
import NotificationImg from "./images/notification-img.png";

const DocumentationDetails = ({ cardId }) => {
  const articleRefs = useRef({});

  useEffect(() => {
    if (articleRefs.current[cardId]) {
      articleRefs.current[cardId].scrollIntoView({ behavior: 'smooth' });
    }
  }, [cardId]);

  const [activeItem, setActiveItem] = useState(1);

  const handleClick = (itemId) => {
    setActiveItem(itemId);
    const contentDiv = document.getElementById(`item${itemId}Content`);
    if (contentDiv) {
      contentDiv.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.documentDetailsPageOuter}>
      <div className={styles.documentDetailsPageLeft}>
        <ul className="docs-article">
          <li className={activeItem === 1 ? styles.activeDocs : ''} onClick={() => handleClick(1)}>Introduction WyngMate</li>
          <li className={activeItem === 2 ? styles.activeDocs : ''} onClick={() => handleClick(2)}>WyngMate Account</li>
          <li className={activeItem === 3 ? styles.activeDocs : ''} onClick={() => handleClick(3)}>WyngMate Plans</li>
          <li className={activeItem === 4 ? styles.activeDocs : ''} onClick={() => handleClick(4)}>Organising WyngMate</li>
          <li className={activeItem === 5 ? styles.activeDocs : ''} onClick={() => handleClick(5)}>Organising Collection</li>
          <li className={activeItem === 6 ? styles.activeDocs : ''} onClick={() => handleClick(6)}>Design APIs</li>
          <li className={activeItem === 7 ? styles.activeDocs : ''} onClick={() => handleClick(7)}>Mocking Request</li>
          <li className={activeItem === 8 ? styles.activeDocs : ''} onClick={() => handleClick(8)}>Collection Sharing</li>
          <li className={activeItem === 9 ? styles.activeDocs : ''} onClick={() => handleClick(9)}>Import Export</li>
          <li className={activeItem === 10 ? styles.activeDocs : ''} onClick={() => handleClick(10)}>Managing Environment</li>
          <li className={activeItem === 11 ? styles.activeDocs : ''} onClick={() => handleClick(11)}>Managing History</li>
          <li className={activeItem === 12 ? styles.activeDocs : ''} onClick={() => handleClick(12)}>Managing Notification</li>
        </ul>
      </div>
      <div className={styles.documentDetailsPageRight}>
        <article id="item1Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Introduction to WyngMate</h1>
            <section className="docs-intro">
              <p>WyngMate intuitive interface and powerful tools, creating APIs has never been easier. Design APIs visually or import existing specifications effortlessly, allowing you to focus on innovation rather than complexities</p>
            </section>
          </header>
          <section className="docs-section">
            <p>WyngMate is indeed a groundbreaking tool for developers, embodying the principles of power and accessibility that are crucial in modern technology. Your vision for creating a user-friendly and visually appealing personal computer reflects the same ethos that drives WyngMate's development. Both projects prioritize simplicity, elegance, and functionality, creating experiences that resonate with users on a deeper level.</p>
          </section>
          <section className="docs-section" >
            <p>The commitment to form and function in WyngMate mirrors your dedication to crafting technology that people not only need but also genuinely want to use. This shared spirit of innovation emphasizes the importance of creating tools and products that not only meet practical needs but also inspire users and enhance their overall experience.</p>
          </section>
          <section className="docs-section">
            <ul>
              <li><strong>Debug APIs</strong> using the most popular protocols and formats.</li>
              <li><strong>Design APIs</strong> using the native Open API editor and visual preview.</li>
              <li><strong>Test APIs</strong> using native test suites.</li>
              <li><strong>Mock APIs</strong> you can mock your request.</li>
            </ul>
          </section>
          <div className={styles.imageContainer}>
            <img src={IntroImg} alt="introImg" />
          </div>
        </article>
        <article id="item2Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">WyngMate Account</h1>
            <section className="docs-intro">
              <p>WyngMate seems to offer two options for user authentication during the login process: OTP (One-Time Password) and Google login. Here's a brief explanation of each option.</p>
            </section>
          </header>
          <section className="docs-section">
            <p><strong>OTP (One-Time Password) Login</strong></p>
            <ul>
              <li>With OTP login, users typically enter their your email address.</li>
              <li>After entering the mobile number or email, the system sends a one-time password to that email address.</li>
              <li>The user then enters this OTP to complete the login process.</li>
              <li>OTP login is commonly used as an additional layer of security to verify the user's identity during the login process.</li>
            </ul>
            <p>1. Firts enter your email form login with otp</p>
            <div className={styles.imageContainer}>
              <img src={LoginImg} alt="LoginImg" />
            </div>
            <p>2. Check your email inbox and enter otp</p>
            <div className={styles.imageContainer}>
              <img src={LoginOtp} alt="LoginOtp" />
            </div>
            <p>3. Click on Login button login successfully</p>
          </section>
          <section className="docs-section">
            <p><strong>Google Login</strong></p>
            <ul>
              <li>Google login allows users to log in to a platform or service using their Google account credentials.</li>
              <li>When users choose Google login, they are redirected to a Google authentication page where they can enter their Google username (email address) and password.</li>
              <li>After entering the credentials, Google verifies the user's identity and grants access to the platform if the credentials are correct.</li>
              <li>Google login is convenient for users who already have a Google account and prefer to use their existing credentials for authentication.</li>
            </ul>
            <p>Just click on sign in with google button</p>
            <div className={styles.imageContainer}>
              <img src={EmailLogin} alt="EmailLogin" />
            </div>
          </section>
        </article>
        <article id="item3Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">WyngMate Plans</h1>
            <section className="docs-intro">
              <p>WyngMate offers a variety of subscription plans to suit your needs, with options for both monthly and yearly subscriptions. Whether you're an individual developer or a large team, WyngMate has a plan that's right for you.</p>
            </section>
          </header>
          <section className="docs-section">
            <p><strong>Monthly Subscription</strong></p>
            <p>This plan is ideal for individuals or small teams who need access to WyngMate's API development tools on a monthly basis. With a monthly subscription, you can easily manage your APIs and collaborate with your team without a long-term commitment.</p>
            <ul>
              <li>The monthly plan is a subscription option where users pay a fee on a monthly basis to access WyngMate's services, features, and content.</li>
              <li>This plan is suitable for users who prefer a short-term commitment or want to try out the platform before committing to a longer-term subscription.</li>
              <li>Users on the monthly plan usually have access to all the standard features and resources offered by WyngMate during the subscription period.</li>
              <li>Payments for the monthly plan are made monthly, and users can choose to renew or cancel their subscription at the end of each month.</li>
            </ul>
            <div className={styles.imageContainer}>
              <img src={MonthlyPlan} alt="MonthlyPlan" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Yearly Subscription</strong></p>
            <p>This plan is perfect for larger teams or organizations who need access to WyngMate's API development tools on a yearly basis. With a yearly subscription, you can enjoy significant savings and ensure that your team has the tools they need to develop and manage APIs all year round.</p>
            <ul>
              <li>The yearly plan is a subscription option where users pay a lump sum upfront for an entire year of access to WyngMate's services, features, and content.</li>
              <li>This plan often comes with benefits such as discounted pricing compared to the monthly plan. Users can save money by opting for the yearly subscription instead of paying month-to-month.</li>
              <li>Users on the yearly plan also have access to all the standard features and resources offered by WyngMate throughout the subscription period.</li>
              <li>Payments for the yearly plan are made once per year, and users can choose to renew or cancel their subscription at the end of each yearly cycle.</li>
            </ul>
            <div className={styles.imageContainer}>
              <img src={YearlyPlan} alt="YearlyPlan" />
            </div>
          </section>
        </article>
        <article id="item4Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Organising WyngMate</h1>
            <section className="docs-intro">
              <p>WyngMate is all about keeping your API development process organized and efficient. With WyngMate, you can easily manage your collections, ensuring that your APIs are well-structured and easy to navigate. This is similar to how we believe in the importance of a clean and intuitive user interface. Just as users should be able to easily find what they're looking for in our products, developers should be able to easily find and manage their APIs in WyngMate.</p>
            </section>
          </header>
          <section className="docs-section">
            <p><strong>Collection Management: </strong></p>
            <p>WyngMate allows developers to organize APIs into collections based on various criteria such as functionality, versioning, or target audience. Collections help in grouping related APIs together, making it easier to manage and maintain them.</p>
          </section>
          <section className="docs-section">
            <p><strong>Documentation: </strong></p>
            <p>Proper documentation is essential for any API. WyngMate offers robust documentation capabilities, allowing developers to create detailed and comprehensive documentation for each API. This documentation includes information about endpoints, parameters, response formats, authentication methods, and more.</p>
          </section>
          <section className="docs-section">
            <p><strong>Version Control:  </strong></p>
            <p>Managing API versions is critical to ensure backward compatibility and smooth transitions for API consumers. WyngMate supports version control, allowing developers to track changes, deprecate outdated versions, and communicate version updates effectively.</p>
          </section>
          <section className="docs-section">
            <p><strong>Security and Access Control: </strong></p>
            <p>WyngMate provides security features such as API key management, authentication mechanisms, and access control policies. Developers can configure security settings to protect APIs and restrict access based on roles and permissions.</p>
          </section>
          <section className="docs-section">
            <p><strong>Testing and Monitoring: </strong></p>
            <p>Efficient API development involves thorough testing and monitoring. WyngMate integrates with testing tools and provides monitoring capabilities to track API performance, identify issues, and optimize functionality.</p>
          </section>

        </article>
        <article id="item5Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Organising Collection</h1>
            <section className="docs-intro">
              <p>WyngMate allows you to organize your APIs into collections, making it easy to manage and maintain your work. By grouping related APIs together, you can keep your API development process organized and efficient. This is similar to how we  believe in the importance of a clean and intuitive user interface. Just as users should be able to easily find what they're looking for in our products, developers should be able to easily find and manage their APIs in WyngMate.</p>
            </section>
          </header>
          <section className="docs-section">
            <p><strong>Purpose of Organizing Collections: </strong></p>
            <p>Just a emphasizes clean UI for user convenience, organizing collections in WyngMate aims to enhance developer experience.
              The primary goal is efficient management and maintenance of APIs by grouping related ones together.</p>
          </section>
          <section className="docs-section">
            <p><strong>User Interface (UI) Analogy: </strong></p>
            <p>In products, a clean and intuitive UI allows users to navigate effortlessly and find what they need without confusion.
              Similarly, organized collections in WyngMate help developers quickly locate and manage APIs, streamlining their workflow.</p>
          </section>
          <section className="docs-section">
            <p><strong>Benefits of Organized Collections:  </strong></p>
            <ul>
              <li><strong>Efficiency</strong> Developers can work more efficiently when APIs are logically grouped, reducing time spent searching for specific functionalities.</li>
              <li><strong>Clarity</strong> Just as a clear UI aids user understanding, organized collections provide clarity on API functionalities and relationships.</li>
              <li><strong>Maintenance</strong>It's easier to maintain and update APIs when they are structured within collections, ensuring consistency and coherence.</li>
            </ul>
          </section>
          <section className="docs-section">
            <p><strong>User Experience (UX) Impact: </strong></p>
            <p>Seamless interactions; likewise, organized collections contribute to a positive developer experience in WyngMate.
              Developers can focus on coding and innovation rather than dealing with disorganized API structures.</p>
          </section>
          <section className="docs-section">
            <p><strong>Parallel Principles: </strong></p>
            <p> API organization principles emphasize simplicity, accessibility, and user-centricity.
              Just products aim for an enjoyable user experience, WyngMate aims for a developer-friendly environment.</p>
          </section>
          <section className="docs-section">
            <p><strong>Continuous Improvement: </strong></p>
            <p>Similar to refines its UI based on user feedback, WyngMate can enhance its collection organization features based on developer input.
              Iterative improvements ensure that the platform remains user-friendly and meets evolving developer needs.</p>
          </section>
          <section className="docs-section">
            <p><strong>Overall Impact: </strong></p>
            <p>Organized collections in WyngMate contribute to a smoother API development process, fostering productivity and innovation among developers.
              The analogy UI design underscores the importance of user-centricity in software tools and platforms.</p>
          </section>
        </article>
        <article id="item6Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Design APIs</h1>
            <section className="docs-intro">
              <p>Designing APIs is indeed a crucial step in the API development process, as it lays the foundation for how developers will interact with and utilize your APIs. Just commitment to design, focusing on the usability, functionality, and aesthetics of your APIs can greatly enhance the overall developer experience.</p>
            </section>
          </header>
          <section className="docs-section">
            <p><strong>Functionality</strong></p>
            <p>Define the core functionality of your APIs. What tasks or operations will your APIs enable developers to perform? For example, if you're creating an e-commerce API, functionalities may include product listing, cart management, checkout process, etc.</p>
          </section>
          <section className="docs-section">
            <p><strong>Endpoints and Methods: </strong></p>
            <p>Design the endpoints and methods that developers will use to access and manipulate data through your APIs. Use RESTful principles to create clear and intuitive endpoint structures (e.g., GET /products, POST /cart/add, DELETE /cart/remove).</p>
          </section>
          <section className="docs-section">
            <p><strong>Request and Response Formats: </strong></p>
            <p>Define the request formats (e.g., JSON) that your APIs will accept, as well as the response formats they will return. Ensure consistency and clarity in data structures to facilitate easy integration and understanding.</p>
          </section>
          <section className="docs-section">
            <p><strong>Authentication and Authorization: </strong></p>
            <p>Determine the authentication mechanisms (e.g., API keys, OAuth tokens) required to access your APIs. Implement authorization mechanisms to control access based on user roles and permissions.</p>
          </section>
          <section className="docs-section">
            <p><strong>Error Handling: </strong></p>
            <p>Design a robust error handling mechanism to provide meaningful error messages and status codes (e.g., 400 Bad Request, 401 Unauthorized) in case of invalid requests or server-side errors. This helps developers troubleshoot issues effectively.</p>
          </section>
          <section className="docs-section">
            <p><strong>Versioning: </strong></p>
            <p>Consider versioning your APIs to manage changes and updates without breaking existing integrations. Use version numbers in endpoint paths (e.g., /v1/products) and provide documentation for each version's changes.</p>
          </section>
          <section className="docs-section">
            <p><strong>Documentation: </strong></p>
            <p>Create comprehensive documentation for your APIs, including endpoints, request parameters, response formats, authentication methods, error codes, and usage examples. Use tools like Swagger or OpenAPI to generate interactive API documentation.</p>
          </section>
          <section className="docs-section">
            <p><strong>Testing and Validation: </strong></p>
            <p>Test your APIs thoroughly to ensure they function as intended and handle edge cases gracefully. Implement input validation to prevent malformed or malicious requests from affecting your system.</p>
          </section>
          <section className="docs-section">
            <p><strong>Performance and Scalability: </strong></p>
            <p>Optimize your APIs for performance and scalability by minimizing response times, implementing caching mechanisms, and designing for horizontal scalability to handle increasing loads.</p>
          </section>
          <section className="docs-section">
            <p><strong>Feedback and Iteration: </strong></p>
            <p>Gather feedback from developers using your APIs and iterate based on their suggestions and pain points. Continuously improve and update your APIs to meet evolving needs and industry standards.</p>
          </section>
        </article>
        <article id="item7Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Mocking Request</h1>
            <section className="docs-intro">
              <p>We can test and debug your APIs by mocking requests and responses. This is a crucial step in the API development process, as it allows you to ensure that your APIs are working correctly before you deploy them. we've always believed in the importance of testing and debugging, and we've always strived to create products that are reliable and robust. I see the same commitment to quality in WyngMate's mocking request section</p>
            </section>
          </header>
          <section className="docs-section">
            <p><strong>Open the Request: </strong></p>
            <p>Navigate to the request that you want to mock within WyngMate. This likely means accessing the API endpoint or specific functionality you wish to work with.</p>
          </section>
          <section className="docs-section">
            <p><strong>Select "Mock this Request" Checkbox to Action (CTA):</strong></p>
            <p>Look for a button or option labeled "Mock this request" and check on it. This action likely indicates that you want to create a mock version of the request.</p>
            <div className={styles.imageContainer}>
              <img src={MockIcon} alt="MockIcon" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Select "Mock Request" Tab to Action (CTA):</strong></p>
            <p>Look for a button or option labeled "Mock Request" and click on it.</p>
            <div className={styles.imageContainer}>
              <img src={MockRequestImg} alt="MockRequestImg" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Add Mock Details:</strong></p>
            <p>Fill in all the necessary details for your mocked request. This includes parameters, headers, and any other relevant information. You may also specify the desired response for this mocked request.</p>
            <div className={styles.imageContainer}>
              <img src={MockDetails} alt="MockDetails" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Copy Actual Request (Optional):</strong></p>
            <p>If needed, you can copy details from an actual request by selecting the "Copy Actual Request" CTA. This feature can streamline the process if you're starting from an existing request.</p>
            <div className={styles.imageContainer}>
              <img src={CopyIcon} alt="CopyIcon" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Save Changes:</strong></p>
            <p>After making all the required changes and additions to the mocked request, select the "Save" CTA to save your work. This step ensures that your mocked request is stored and ready for use.</p>
            <div className={styles.imageContainer}>
              <img src={SaveChange} alt="SaveChange" />
            </div>
            <p>Click on the actual request tab</p>
            <div className={styles.imageContainer}>
              <img src={ActualTab} alt="ActualTab" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Send Mock Request:</strong></p>
            <p>When you want to send the mocked request for testing or development purposes, make sure to select the "Mock this request" CTA before sending the request. This action distinguishes the request as a mocked one rather than a live, operational request.</p>
            <div className={styles.imageContainer}>
              <img src={SendClickMockImg} alt="SendClickMockImg" />
            </div>
            <p>When you want to mock resposnse simply check mock this rquest checkbox you get mock response</p>
            <div className={styles.imageContainer}>
              <img src={MockResponse} alt="MockResponse" />
            </div>
            <p>If you needed actaul response before mock just uncheck mock this request checkbox and click on send button you get actual response</p>
            <div className={styles.imageContainer}>
              <img src={SendClickImg} alt="SendClickImg" />
            </div>
            <p>Actual Response</p>
            <div className={styles.imageContainer}>
              <img src={ActualResponse} alt="ActualResponse" />
            </div>
          </section>
          <section className="docs-section">
            <p>These steps outline a workflow within WyngMate for creating and using mocked requests, which can be valuable for API design and testing. It allows developers to simulate API behavior and design responses before fully implementing them.</p>
          </section>
        </article>
        <article id="item8Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Collection Sharing</h1>
            <section className="docs-intro">
              <p>We can share your APIs with others, making it easy to collaborate and work together on API development projects. This is a crucial step in the API development process, as it allows you to leverage the skills and expertise of your team members. we've always believed in the importance of collaboration, and we've always strived to create products that are easy to use and share. I see the same commitment to collaboration in WyngMate's collection sharing section.</p>
            </section>
          </header>
          <section className="docs-section">
            <p><strong>Access Collection: </strong></p>
            <p>Log in to your WyngMate account.
              Navigate to the collection you want to share. Collections are typically organized by topics, projects, or themes.</p>
          </section>
          <section className="docs-section">
            <p><strong>Select Share:</strong></p>
            <p>Once you're in the collection interface, look for the "Share" option. This option is usually located in the toolbar or menu at the top of the collection page.
              Click on "Share" to initiate the sharing process.</p>
            <div className={styles.imageContainer}>
              <img src={ShareIcon} alt="ShareIcon" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Enter Recipients' Email Addresses:</strong></p>
            <p>A pop-up window or dialog box will appear after clicking on "Share." In this window, you'll see a field to enter email addresses.
              Enter the email addresses of the people you want to share the collection with. You can share with one or multiple recipients.
              Separate multiple email addresses with commas or semicolons.</p>
            <p><strong>Press Enter:</strong></p>
            <p>After entering each email address, press the Enter key on your keyboard to confirm and add the email address to the sharing list.
              Repeat this step if you're sharing with multiple recipients.</p>
            <div className={styles.imageContainer}>
              <img src={EnterEmail} alt="EnterEmail" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Change Collection Access</strong></p>
            <p>Within the collection properties, look for an option related to permissions or access control. Find the default access setting, which is currently set to "edit," and change it to "view."</p>
            <div className={styles.imageContainer}>
              <img src={ChangAccess} alt="ChangAccess" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Share:</strong></p>
            <p>Once you've added all the desired recipients' email addresses, look for the "Share" button within the pop-up window.
              Click on the "Share" button to send invitations to the specified email addresses.</p>
          </section>
          <section className="docs-section">
            <p><strong>Invitations Sent:</strong></p>
            <p>WyngMate will send email invitations to the recipients you've specified. These invitations will include instructions on how to access the shared collection.</p>
          </section>
          <section className="docs-section">
            <p><strong>Recipient Access:</strong></p>
            <p>The recipients will receive the email invitations in their inbox.
              They will need to click on the invitation link and follow the instructions to access the shared collection.
              If they don't have a WyngMate account, they may need to create one to view and collaborate on the collection.</p>
          </section>
          <section className="docs-section">
            <p>By following these steps, you can easily share collections in WyngMate and collaborate with colleagues, team members, or stakeholders on various projects or knowledge areas. This sharing feature promotes teamwork, knowledge dissemination, and efficient collaboration within the platform.</p>
          </section>
        </article>
        <article id="item9Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Import Export</h1>
            <section className="docs-intro">
              <p>We can import and export your Collection making it easy to transfer your work between different environments or share it with others. This is a crucial step in the API development process, as it allows you tomove your APIs between different stages of development or share them with other teams.  we've always believed in the importance of flexibility and portability, and we've always strived to create products that are easy to use and integrate with other tools. I see the same commitment to flexibility and portability in WyngMate's import/export section.</p>
            </section>
          </header>
          <section className="docs-section">
            <p>It's great to hear that you appreciate the import/export functionality in WyngMate! Importing and exporting collections is indeed a crucial aspect of API development, as it facilitates seamless transitions between different environments and enables collaboration among teams.</p>
          </section>
          <section className="docs-section">
            <p>The ability to transfer your work between environments ensures that your APIs can progress through various stages of development smoothly. It also allows you to share your collections with other teams or individuals, promoting collaboration and enhancing productivity.</p>
          </section>
          <section className="docs-section">
            <p>Flexibility and portability are key factors in modern software development, and tools like WyngMate that prioritize these aspects make it easier for developers to work efficiently and integrate their work with other tools seamlessly. By focusing on user-friendly import/export features, WyngMate enables users to leverage their APIs effectively across different platforms and environments, aligning philosophy of creating products that are easy to use and integrate into existing workflows.</p>
          </section>
          <section className="docs-section">
            <p>Click on export icon for exporting collection</p>
            <div className={styles.imageContainer}>
              <img src={ExportImg} alt="ExportImg" />
            </div>
          </section>
          <section className="docs-section">
            <p>Click on import for importaing collection</p>
            <div className={styles.imageContainer}>
              <img src={ImportImg} alt="ImportImg" />
            </div>
          </section>
        </article>
        <article id="item10Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Managing Environment</h1>
            <section className="docs-intro">
              <p>Environment plays a crucial role in the API development process, as it focuses on ensuring that APIs are thoroughly tested and deployed in appropriate environments. This step is essential for guaranteeing that APIs work correctly across various settings and conditions. the significance of testing and debugging has always been emphasized, with a continuous effort to deliver reliable and robust products. This commitment to quality is also reflected in WyngMate's approach to managing environments.</p>
            </section>
          </header>
          <section className="docs-section">
            <p><strong>Environment Creation: </strong></p>
            <p>WyngMate allows users to create multiple environments such as development, testing, staging, and production. Each environment can have its own set of variables, configurations, and endpoints.</p>
            <div className={styles.imageContainer}>
              <img src={EnvCreate} alt="EnvCreate" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Variables Management:</strong></p>
            <p>Users can define variables within each environment, which can be used in API requests and scripts. These variables could include base URLs, API keys, authentication tokens, and other parameters specific to each environment.</p>
            <p>click on click to add new variable or plus icon to add multiple variables</p>
            <div className={styles.imageContainer}>
              <img src={VariableCreate} alt="VariableCreate" />
            </div>
            <p>Click on save button to save your variables</p>
            <div className={styles.imageContainer}>
              <img src={EnvSave} alt="EnvSave" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Environment Switching: </strong></p>
            <p>WyngMate provides a seamless way to switch between different environments within the platform's interface. This makes it easy for developers and testers to work with different configurations and endpoints without manually modifying requests.</p>
          </section>
          <section className="docs-section">
            <p><strong>Environment Templates: </strong></p>
            <p>The platform may offer predefined templates for common environments, saving time and effort in setting up new environments. Users can customize these templates according to their project requirements.</p>
          </section>
          <section className="docs-section">
            <p><strong>Environment-specific Scripts: </strong></p>
            <p>WyngMate allows users to write scripts or tests specific to each environment. For example, users can create pre-request scripts to dynamically generate authentication tokens or set up environment-specific assertions in test scripts.</p>
          </section>
          <section className="docs-section">
            <p><strong>Environment Sharing: </strong></p>
            <p>Collaborative features enable users to share environments with team members or collaborators. This ensures consistency across development, testing, and deployment phases.</p>
          </section>
        </article>
        <article id="item11Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Managing History</h1>
            <section className="docs-intro">
              <p>We can view and manage the history of your API development projects, making it easy to track changes and revert to previous versions if necessary. This is a crucial step in the API development process, as it allows you to maintain control over your work and ensure that you can always revert to a previous version if needed. we've always believed in the importance of version control, and we've always strived to create products that are easy to use and maintain. I see the same commitment to version control in WyngMate's managing history section.</p>
            </section>
          </header>
          <section className='docs-section'>
            <p>When you click on the history tab, you can view the history of the latest API calls made in the collection.</p>
            <div className={styles.imageContainer}>
              <img src={HistoryImg} alt="HistoryImg" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Tracking Changes: </strong></p>
            <p>The history management feature records every change made to the API project. This includes modifications to code, endpoints, documentation, and configurations. Having a detailed log of changes helps developers understand the evolution of the API and the rationale behind specific decisions.</p>
          </section>
          <section className="docs-section">
            <p><strong>Collaboration:</strong></p>
            <p>In a collaborative development environment, multiple team members may work on different aspects of the API simultaneously. The history management tool ensures that changes from various contributors are tracked, preventing conflicts and ensuring consistency across the project.</p>
          </section>
          <section className="docs-section">
            <p><strong>Error Recovery: </strong></p>
            <p>Despite thorough testing, errors can occur in software development. The ability to revert to a previous version simplifies error recovery. Developers can quickly identify the problematic changes and roll back to a stable state, minimizing downtime and user impact.</p>
          </section>
          <section className="docs-section">
            <p><strong>Auditing and Compliance: </strong></p>
            <p>For regulatory compliance or internal auditing purposes, having a detailed history of API changes is essential. It provides transparency into development practices, facilitating assessments, and ensuring adherence to standards and best practices.</p>
          </section>
          <section className="docs-section">
            <p><strong>Continuous Improvement: </strong></p>
            <p>Analyzing the history of API changes helps teams identify patterns, trends, and areas for improvement. By reviewing past iterations, developers can implement enhancements, optimize performance, and refine APIs based on user feedback and evolving requirements.</p>
          </section>
        </article>
        <article id="item12Content" className="contentDiv docs-article" >
          <header className="docs-header">
            <h1 className="docs-heading">Managing Notification</h1>
            <section className="docs-intro">
              <p>We can manage your API development notifications, ensuring that you're always up-to-date on the status of your projects. This is a crucial step in the API development process, as it allows you to stay informed and respond quickly to any issues that may arise. we've always believed in the importance of communication and collaboration, and we've always strived to create products that are easy to use and share. I see the same commitment to communication and collaboration in WyngMate's managing notification section.</p>
            </section>
          </header>
          <section className='docs-section'>
            <div className={styles.imageContainer}>
              <img src={NotificationImg} alt="NotificationImg" />
            </div>
          </section>
          <section className="docs-section">
            <p><strong>Purpose and Importance: </strong></p>
            <ul>
              <li><strong>Enhanced Collaboration: </strong>Collection Share Notification enhances collaboration by keeping all relevant parties informed when a collection is shared with them. This ensures that everyone is aware of new resources or data they have access to, promoting collaboration and teamwork.</li>
              <li><strong>Transparency: </strong>Notifications promote transparency by clearly indicating who shared the collection and with whom it was shared. This transparency helps in maintaining accountability and clarity within the collaborative environment.</li>
              <li><strong>Efficient Communication: </strong>Instead of relying on manual communication methods (e.g., emails, messages), automated notifications streamline the communication process, saving time and reducing the chances of miscommunication.</li>
            </ul>
          </section>
          <section className="docs-section">
            <p><strong>Key Components: </strong></p>
            <ul>
              <li><strong>Sender Information: </strong>The notification typically includes information about the user who shared the collection, including their name or username.</li>
              <li><strong>Recipient Information: </strong>It also includes details about the recipients who gained access to the shared collection, such as their names or email addresses.</li>
              <li><strong>Collection Details: </strong>The notification may include details about the shared collection, such as its name, description, and any specific permissions or access rights granted to the recipients.</li>
            </ul>
          </section>
        </article>
      </div>
    </div>
  )
}

export default DocumentationDetails;
