import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import LoadingSpnner from '../LoadingSpnner/LoadingSpnner';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        navigate('/home');
    }, [])

    return (
        <LoadingSpnner />
    )
}

export default Logout;