import React from 'react';
import ContactForm from './ContactForm';
// import logo from "./images/logo.png";
import { ContactSupport } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import Navbar from '../Navbar/NavBar';
import Footer from '../Footer/Footer';
import ReactGA from "react-ga4";

const Contact = () => {

  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Web ContactUs Page" });

  return (
    <>
      <Navbar />
      <div className="page-header theme-bg-dark py-5 text-center position-relative">
        <div className="theme-bg-shapes-right"></div>
        <div className="theme-bg-shapes-left"></div>
        <div className="container">
          <h1 className="page-heading single-col-max mx-auto">Contact Us</h1>
          <div className="page-intro single-col-max mx-auto">Get in touch if you have any questions or want to get a custom quote</div>
        </div>
      </div>
      <div className="options-section page-content py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 mb-5">
              <div className="row contact-card shadow-sm p-4 rounded h-100 m-0 align-items-center">
                <div className="col-2 p-0">
                  <ContactSupport style={{ width: "50%", height: "50%" }} />
                </div>
                <div className="col">
                  <h5 className="mt-0">Customer Support</h5>
                  <ul className='list-unstyled'>
                    <li>Email: <a href="mailto:admin@wyngmate.com">admin@wyngmate.com</a> </li>
                    <li>Phone: <a href="tel:+91 9461055836">+91 9461055836</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-5">
              <div className="row contact-card shadow-sm p-4 rounded h-100 m-0 align-items-center">
                <div className="col-2 p-0">
                  <DescriptionIcon style={{ width: "50%", height: "50%" }} />
                </div>
                <div className="col">
                  <h5 className="mt-0">Documentation</h5>
                  <p>Explore our comprehensive documentation for WyngMate. Our documentation covers everything from getting started guides to advanced API usage. More to streamline your development process and unleash the full potential of WyngMate.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
      <Footer />
    </>
  )
}

export default Contact;
