import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/NavBar';
import PlanTab from './PlanTab';
import ReactGA from "react-ga4";

const Pricing = () => {

  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Web Pricing Page" });

  const [activePlanTab, setActivePlanTab] = useState('MONTHLY');

  const handleTabChange = (tabId) => {
    setActivePlanTab(tabId);
  };

  return (
    <>
      <Navbar />
      <Container className="py-5">
        <h1 className="text-center mb-4">Choose your plan</h1>
        <ul className="features-tab nav nav-pills justify-content-center" id="features-tab" role="tablist">
          <li style={{ cursor: 'pointer' }} className="nav-item" role="presentation">
            <div
              className={`nav-link ${activePlanTab === 'MONTHLY' ? 'active' : ''}`}
              role="tab"
              aria-selected={activePlanTab === 'MONTHLY'}
              onClick={() => handleTabChange('MONTHLY')}
            >
              Monthly
            </div>
          </li>
          <li style={{ cursor: 'pointer' }} className="nav-item" role="presentation">
            <div
              className={`nav-link ${activePlanTab === 'YEARLY' ? 'active' : ''}`}
              role="tab"
              aria-selected={activePlanTab === 'YEARLY'}
              onClick={() => handleTabChange('YEARLY')}
            >
              Yearly
            </div>
          </li>
        </ul>
        <div className="tab-content">
          <div className={`tab-pane fade ${activePlanTab === 'MONTHLY' ? 'show active' : ''}`} id="MONTHLY" role="tabpanel">
            <PlanTab activePlanTab={activePlanTab} />
          </div>
          <div className={`tab-pane fade ${activePlanTab === 'YEARLY' ? 'show active' : ''}`} id="YEARLY" role="tabpanel">
            <PlanTab activePlanTab={activePlanTab} />
          </div>
        </div>
        <div className="pt-5 text-center">
          <p>Check out our Free plan to start developing and testing APIs.</p>
          <a className="btn btn-primary me-2 mb-3" href={process.env.REACT_APP_KNOWLIOFORGE_WEBSITE} target="_blank" rel="noreferrer">
            Get Started Free
          </a>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Pricing;
