import React, { createContext, useEffect, useState } from "react";

export const DataContext = createContext(null);
const DataProvider = ({ children }) => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    }
  }, []);

  return (
    <DataContext.Provider
      value={{
        userLoggedIn,
        setUserLoggedIn,
        userInfo,
        setUserInfo
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
