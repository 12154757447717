import React from 'react';
import Navbar from '../Navbar/NavBar';
import DocumentationDetails from './DocumentationDetails';
import ReactGA from "react-ga4";

const Document = () => {

	ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Web Document Page" });

	return (
		<>
			<Navbar />
			<div className="page-header theme-bg-dark py-5 text-center position-relative">
				<div className="theme-bg-shapes-right"></div>
				<div className="theme-bg-shapes-left"></div>
				<div className="container">
					<h1 className="page-heading single-col-max mx-auto">Documentation</h1>
					<div className="page-intro single-col-max mx-auto">
						Open-source API development ecosystem.
					</div>
				</div>
			</div>
			<DocumentationDetails />
		</>
	)
}

export default Document;
