import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import AboutUs from './components/AboutUs/AboutUs';
import Contact from './components/Contact/Contact';
import Document from './components/Document/Document';
import Home from './components/Home/Home';
import Login from './components/Login/Login'
import Logout from './components/Logout/Logout';
import PaymentPage from './components/Payment/PaymentPage';
import Pricing from './components/Pricing/Pricing';
import Privacy from './components/Privacy/Privacy';
import Terms from './components/Terms/Terms';
import { DataContext } from './context/DataProvider';
import ReactGA from "react-ga4";

function App() {
  const TRACKING_ID = 'G-MJ7F6F7K4R';
  ReactGA.initialize(TRACKING_ID);

  const { setUserLoggedIn } = useContext(DataContext);
  
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      setUserLoggedIn(false);
    } else {
      setUserLoggedIn(true);
    }
  }, [setUserLoggedIn])

  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<Navigate to='/home' />} />
        <Route path='/login' element={!localStorage?.getItem('token') ? <Login /> : <Navigate to='/home' />} />
        <Route path='/payment-page' element={!localStorage?.getItem('token') ? <Login /> : <PaymentPage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/docs" element={<Document />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path='/logout' element={<Logout />} />

      </Routes>
    </Router>
  );
}

export default App;