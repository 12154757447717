import React from 'react'
import HandymanIcon from '@mui/icons-material/Handyman';
import TimelineIcon from '@mui/icons-material/Timeline';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const BenefitsSection = () => {
  return (
    <div className="benefits-section theme-bg-light py-5">
      <div className="container">
        <h3 className="mb-3 text-center font-weight-bold section-title">Made For Developers</h3>
        <div className="mb-5 text-center section-intro">API development and testing platform </div>
        <div className="row">
          <div className="item col-12 col-md-6 col-lg-3">
            <div className="item-inner rounded">
              <div className="icon-holder text-center mx-auto mb-3">
                <HandymanIcon />
              </div>
              <h5 className="mb-3">API Tools</h5>
              <div>A comprehensive set of tools that help accelerate the API Lifecycle - from design, testing, documentation, and mocking to discovery.</div>
            </div>
          </div>

          <div className="item col-12 col-md-6 col-lg-3">
            <div className="item-inner rounded">
              <div className="icon-holder text-center mx-auto mb-3">
                <TimelineIcon />
              </div>
              <h5 className="mb-3">API Repository</h5>
              <div>Easily store, iterate and collaborate around all your API artifacts on one central platform used across teams.</div>
            </div>
          </div>

          <div className="item col-12 col-md-6 col-lg-3">
            <div className="item-inner rounded">
              <div className="icon-holder text-center mx-auto mb-3">
                <WorkspacesIcon />
              </div>
              <h5 className="mb-3">Workspaces</h5>
              <div>Organize your API work and collaborate with teammates across your organization or stakeholders across the world.</div>
            </div>
          </div>

          <div className="item col-12 col-md-6 col-lg-3">
            <div className="item-inner rounded">
              <div className="icon-holder text-center mx-auto mb-3">
                <AccountBalanceIcon />
              </div>
              <h5 className="mb-3">Governance</h5>
              <div>Improve the quality of APIs with governance rules that ensure APIs are designed, built, tested, and distributed meeting organizational standards.</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default BenefitsSection;
