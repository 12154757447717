import React, { useEffect, useState, useContext } from 'react';
import styles from './styles.module.css';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { DataContext } from '../../context/DataProvider';
import UserModal from '../UserModal/UserModal';
import Logo from "./images/logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Navbar = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showUserModal, setShowUserModal] = useState(false);
  const { userLoggedIn, userInfo, setUserLoggedIn } = useContext(DataContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleUserModalOpen = () => {
    setShowUserModal(true);
  };

  const handleUserModalClose = () => {
    setShowUserModal(false);
  };

  const onLogoutSuccess = async () => {
    navigate("/logout");
    localStorage.clear();
    setUserLoggedIn(false)
    setShowUserModal(false);
  };

  return (
    <div className="header fixed-top">
      <div className="branding">
        <div className="container-fluid position-relative">
          <nav className="navbar navbar-expand-lg">
            <div className="site-logo">
              <a className="navbar-brand" href="/">
                <img className="logo-icon me-2" src={Logo} alt="logo" />
                <span className="logo-text">WyngMate</span>
              </a>
            </div>
            <div onClick={toggleNavbar} >
              {isCollapsed ? (
                <span className="navbar-toggler-icon"></span>
              ) : (
                <span className="navbar-toggler-cross-icon">&#10005;</span>
              )}
            </div>
            <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`}>
              <ul className="navbar-nav c ms-lg-auto">
                <li className='nav-bar'>
                  <NavLink className="nav-link" to="/home" >Home</NavLink>
                </li>
                {/* <li className='nav-bar'>
                  <NavLink className="nav-link" to="/features">Features</NavLink>
                </li> */}
                <li className='nav-bar'>
                  <NavLink className="nav-link" to="/docs" >Docs</NavLink>
                </li>
                <li className='nav-bar'>
                  <NavLink className="nav-link" to="/pricing">Pricing</NavLink>
                </li>
                <li className='nav-bar'>
                  <NavLink className="nav-link" to="/about-us">About us</NavLink>
                </li>
                <li className='nav-bar'>
                  <NavLink className="nav-link" to="/contact" >Contact us</NavLink>
                </li>
                <li className='nav-bar'>
                  <NavLink className="nav-link" to="/privacy" >Privacy</NavLink>
                </li>
                <li className='nav-bar'>
                  <NavLink className="nav-link" to="/terms">Terms</NavLink>
                </li>
                {/* {userLoggedIn ?
                  <li className={`nav-bar ${styles.loginInfo}`} onClick={handleUserModalOpen}>
                    <div className={styles.saprationBorder} />
                    <AccountCircleIcon sx={{ fontSize: 20 }} />
                    <span>{userInfo && userInfo.name}</span>
                  </li>
                  :
                  <li className='nav-bar'>
                    <NavLink className="nav-link" to="/login">Login</NavLink>
                  </li>
                } */}
                {userLoggedIn && (
                  <li className={`nav-bar ${styles.loginInfo}`} onClick={handleUserModalOpen}>
                    <div className={styles.saprationBorder} />
                    <AccountCircleIcon sx={{ fontSize: 20 }} />
                    <span>{userInfo && userInfo.name}</span>
                  </li>
                )}
              </ul>
              {showUserModal && <UserModal onClose={handleUserModalClose} userInfo={userInfo} onLogoutSuccess={onLogoutSuccess} />}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
