import React from 'react'
import CardsContainer from './CardContainer';

const Featuers = () => {
  return (
    <>
      <CardsContainer />
    </>
  )
}

export default Featuers;
