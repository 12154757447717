import React, { useState } from 'react';
import { ADMIN_SUPPORT_API } from '../../constants/apiEndpoints';
import apiService from '../../service/backendApiService';
import { showErrorMessage, showToastMessage } from '../../utils/common-utils';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = formData
    await apiService.post(
      process.env.REACT_APP_ADMIN_SERVICE_URL + ADMIN_SUPPORT_API,
      payload
    )
      .then(async (response) => {
        if (response && response.status === 200) {
          showToastMessage(response?.data?.message);
        }
      })
      .catch((e) => {

        showErrorMessage(e);
      });
    setFormData({
      name: '',
      email: '',
      message: ''
    })
  };

  return (
    <div className="container contact-form-section mb-5">
      <form
        id="contact-form"
        className="contact-form p-5 col-lg-9 mx-lg-auto theme-bg-light shadow"
        onSubmit={handleSubmit}
      >
        <h3 className="text-center">Ask A Question</h3>
        <div className="mb-3 text-center">
          For pre-sale and general questions, please use the form below.
        </div>
        <div className="row g-3">
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              id="cname"
              name="name"
              placeholder="Name"
              minLength="2"
              required
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <input
              type="email"
              className="form-control"
              id="cemail"
              name="email"
              placeholder="Email"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="col-12">
            <textarea
              className="form-control"
              id="cmessage"
              name="message"
              placeholder="Enter your message"
              rows="10"
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="col-12">
            <button type="submit" className="btn w-100 btn-primary py-2">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
