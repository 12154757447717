import React from 'react';
import styles from "./styles.module.css";

const CustomersSection = () => {

  return (
    <div className="container py-5">
      <h1 className="text-center mt-4 mb-4">Our clients</h1>
      <div className="row">
        <div className="col-sm-12 col-md-8 offset-md-2 text-center">
          <h2 className="mb-2">Following companies utilize WyngMate's services.</h2>
          <p>Numerous leading organizations worldwide have integrated the WyngMate API Platform into their operations.</p>
        </div>
      </div>
      <br/>
      <div className="row d-flex">
        {/* <div className="col-6 col-sm-6 col-md-4 col-lg mb-2">
          <div
            className={`${styles.cars24Logo} ${styles.logoSection}`}
            title="Cars24"
          />
        </div> */}
        <div className="col-6 col-sm-6 col-md-4 col-lg mb-2">
          <div
            className={`${styles.logoSection} ${styles.delhiveryLogo}`}
            title="Delhivery"
          />
        </div>
        <div className="col-6 col-sm-6 col-md-4 col-lg mb-2">
          <div
            className={`${styles.logoSection} ${styles.mamaEarthLogo}`}
            title="MamaEarth"
          />
        </div>
        <div className="col-6 col-sm-6 col-md-4 col-lg mb-2">
          <div
            className={`${styles.logoSection} ${styles.goboltLogo}`}
            title="Gobolt"
          />
        </div>
        <div className="col-6 col-sm-6 col-md-4 col-lg mb-2">
          <div
            className={`${styles.logoSection} ${styles.ixigoLogo}`}
            title="Ixigo"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomersSection;
