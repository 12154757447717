import axios from 'axios';

const instance = axios.create();

instance.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('token');

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(accessToken)}`;
  } 
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error && error?.response?.status === 401) {
      window.location.href = '/logout?unauthorized=true';
    }
    throw error;
  }
);

const apiService = {
  getWithHeaders: (endPoint, headers) => {
    var tempInstance = axios.create();
    tempInstance.interceptors.request.use(function (config) {
      let accessToken = localStorage.getItem('token');
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    });

    const config = {};
    if (!endPoint) {
      throw Error('endPoint is required params');
    } else {
      config.headers = headers[0];
      return tempInstance.get(endPoint, config);
    }
  },

  get: (endPoint, data, headers = {}, responseType = null) => {
    const config = {};

    if (!endPoint) {
      throw Error('Url endPoint is required parameters');
    } else {
      if (data) {
        config.params = data;
      }

      config.headers = headers;
      if (responseType) config.responseType = responseType;
      return instance.get(endPoint, config);
    }
  },

  post: (endPoint, data, headers = { 'Content-Type': 'application/json' }) => {
    if (!(endPoint || !data)) {
      throw Error('Url endPoint and data are required parameters');
    }
    return instance.post(endPoint, data, { headers });
  },

  put: (endPoint, data, headers = { 'Content-Type': 'application/json' }) => {
    if (!(endPoint || !data)) {
      throw Error('Url endPoint and data are required params');
    }
    return instance.put(endPoint, data, { headers });
  },

  delete: (endPoint, data, headers = {}) => {
    const config = {};
    if (!endPoint) {
      throw Error('Url endPoint is required params');
    } else {
      config.headers = headers;
      return instance.delete(endPoint, { data: data }, config);
    }
  },
};

export default apiService;
