import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./styles.module.css";
import LinkedInImg from "./images/linkedinImg.png";

const Footer = () => {

  const navigate = useNavigate()

  return (
    <div>
      <div className="cta-section text-center py-5 theme-bg-dark position-relative">
        <div className="theme-bg-shapes-right"></div>
        <div className="theme-bg-shapes-left"></div>
        <div className="container">
          <h3 className="mb-2 text-white mb-3">Get Started in No Time</h3>
          <div className="section-intro text-white mb-3 single-col-max mx-auto">All the information needed to deliver high-quality APIs through standards and collaboration with the WyngMate API design platform.</div>
          <div className="pt-3 text-center">
            <a className="btn btn-light" href={process.env.REACT_APP_KNOWLIOFORGE_WEBSITE} target="_blank" rel="noreferrer">Get Started Free</a>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container py-5 mb-3">
          <div className="row">
            <div className="footer-col col-6 col-lg-3">
              <h4 className="col-heading">Products</h4>
              <ul className={`list-unstyled ${styles.footerItem}`}>
                <li>Workspaces</li>
                <li>Collection</li>
                <li>Environment</li>
                <li onClick={() => navigate('/pricing')}>Plans</li>

              </ul>
            </div>
            <div className="footer-col col-6 col-lg-3">
              <h4 className="col-heading">Developers</h4>
              <ul className={`list-unstyled ${styles.footerItem}`}>
                <li onClick={() => navigate('/pricing')}>Pricing</li>
                <li onClick={() => navigate('/docs')}>Docs</li>
              </ul>
            </div>
            <div className="footer-col col-6 col-lg-3">
              <h4 className="col-heading">Social</h4>
              <ul className={`list-unstyled ${styles.footerItem}`}>
                <li><a href='https://www.linkedin.com/company/wyngmate/about/' target="_blank"><img src={LinkedInImg} />LinkedIn</a></li>
              </ul>
            </div>
            <div className="footer-col col-6 col-lg-3">
              <h4 className="col-heading">WyngMate</h4>
              <ul className={`list-unstyled ${styles.footerItem}`}>
                <li onClick={() => navigate('/about-us')}>About Us</li>
                <li onClick={() => navigate('/contact')}>Contact Us</li>
                <li onClick={() => navigate('/privacy')}>Privacy</li>
                <li onClick={() => navigate('/terms')}>Terms of Services</li>
              </ul>

            </div>
          </div>
        </div>
        <div className="container">
          <hr />
        </div>
        <div className="footer-bottom text-center pb-5">
          <small className="copyright">Copyright©2024WyngMate. All Rights Reserved to WyngMate.</small>
        </div>
      </div>
    </div>
  )
}

export default Footer;
