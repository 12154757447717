import React from 'react';
import { Box, Button, Fade, Modal } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styles from './styles.module.css';

const UserModal = ({ onClose, userInfo, onLogoutSuccess }) => {

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            onClose={onClose}
            closeAfterTransition
        >
            <Fade in={true}>
                <Box className={styles.modalContainer}>
                    <AccountCircleIcon className={styles.icon} />
                    <div className={styles.heading}>
                        <h2>{userInfo && userInfo.name}</h2>
                        <h3>{userInfo && userInfo.email}</h3>
                    </div>
                    <div className={styles.buttonSubmit} type="button" onClick={onLogoutSuccess}>
                        <Button className={styles.submitBtn} variant="contained" color="primary">
                            Logout
                        </Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
};

export default UserModal;