import React from 'react'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/NavBar';
import styles from "./styles.module.css";
import ReactGA from "react-ga4";

const Terms = () => {

  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Web Terms Page" });

  return (
    <div>
      <Navbar />
      <div className="container">
        <h1 className={`page-heading ${styles.heading}`}>TERMS OF USE AND SERVICE</h1>
        <p>
          All products and/or services offered to You (hereinafter referred to as
          &ldquo;<strong>You</strong>&rdquo;, &ldquo;<strong>Your</strong>&rdquo;,
          or &ldquo;<strong>User</strong>&rdquo;) by WyngMate
          and/or its affiliates, subsidiaries, associates and
          partners (hereinafter referred to as &quot;<strong>We</strong>&quot; or
          &quot;<strong>Us</strong>&quot; or &quot;<strong>Our</strong>&quot; or
          &ldquo;<strong>Company</strong>&rdquo;) through its web-portal,
          https://wyngmate.com/, and any other platform offered by the
          Company, and relating advisory (hereinafter collectively referred to as
          the &quot;<strong>Application/s</strong>&quot;) are subject to the terms
          of use and service (hereinafter referred to as
          &quot;<strong>Terms</strong>&quot;) as specified herein.
        </p>
        <p >
          <strong>These Terms, as updated or modified from time to time, constitute an
            agreement between You and Company and shall govern the relationship
            between You and Company with respect to Website and Services
            <em>(as defined hereinafter)</em>. You must read, understand and agree
            to, and be bound by, the Terms for accessing the Services. You must
            not access the Application and Services, in any manner, in case You do
            not expressly accept these Terms in their entirety. Your access to the
            Application and Services shall be rendered as You have expressly
            agreed to these Terms.</strong>
        </p>
        <br />
        <div>
          <p>
            <strong>AGREEMENT</strong>
          </p>
        </div>
        <div>
          These Terms along with privacy policy (hereinafter &ldquo;<strong>Privacy Policy</strong>&rdquo;) and other
          terms and conditions, guidelines, and notes
          specified in the Application (collectively the &ldquo;<strong>Other Terms</strong>&rdquo;) and as may be
          notified and updated from time to time by
          the Company, constitutes a binding agreement between You and
          Company. You must read, understand and accept all the provisions
          envisaged under these Terms, Privacy Policy and Other Terms before
          accessing the Services and/or the Application in any manner. You
          understand and agree that Company reserves the right to modify or
          update these Terms, Privacy Policy and Other Terms at any time
          without needing to serve a prior notice upon You. Any such
          modification or update in the Terms, Privacy Policy and Other Terms
          shall, however, may, at the sole discretion of the Company, be
          intimated to You within the Application. You must accept the Terms,
          Privacy Policy and Other Terms so modified or updated to continue
          accessing the Services and Application. It shall be Your obligation
          to take note of any notification or update to the Terms, Privacy
          Policy and/or Other Terms before using the Application and/or before
          availing the Services. In case You do not accept the Terms, Privacy
          Policy and Other Terms so modified or updated, You must cease to
          access the Services and Application forthwith, and shall terminate
          Your Registration <em>(as referred to hereinafter)</em> and inform
          the Company immediately. In case You continue to access Services and
          Application after any such modification or update to these Terms,
          Privacy Policy and/or Other Terms, it shall be deemed acceptance of
          You and that You have agreed to such modification or update, and You
          shall be bound by the same.
        </div>
        <br />
        <div >
          <p >
            <strong>REGISTRATION</strong>
          </p>
        </div>
        <div >
          <ol >
            <li>
              Your application towards registration as a member and a registered
              user of the Services (such registration as a member shall
              hereinafter be referred to as the
              &ldquo;<strong>Registration</strong>&rdquo;) shall be deemed as You
              having expressly agreed to these Terms, Privacy policy and Other
              Terms, as updated from time to time, and therefore, shall be bound
              by the same at all times. Your Registration as a member and
              registered user of Services and the continuity thereof shall be
              subject to discretion of the Company, and the Company shall reserve
              the right to terminate Your Registration by serving an instant
              notice based on subjective judgement and reasons.
            </li>
            <li>
              Without prejudice to the foregoing, the Registration and access to
              Services shall be allowed to any person above the age of 18 years,
              and the registration for membership shall be guided by the
              registration process stated in the Application. The Company reserves
              the right to verify the age of You as a user of Services by
              requesting for specified documentary proof in this behalf, and shall
              also reserve the right to reject any access to Application and/or
              Services and impose ban in the event any documentary proof is found
              to be false.
            </li>
            <li >
              Notwithstanding, any person requesting registration/membership
              shall, at all times, be bound by the applicable laws in the
              jurisdiction in which such person is situated/ resides. The Company
              shall be not be liable, in any manner, towards violation of any such
              applicable laws by the person requesting Registration/membership.
              Neither Company, nor any other third-party providing any content on
              the Application, nor their related agents shall be responsible and
              liable for any direct or indirect, incidental or consequential
              injuries or damages that can arise as a result of the use of, or
              inability to use, Our Application by any person who is below the age
              of 18 years.
            </li>
            <li >
              You must register Yourself through the Application before accessing
              Services. The Registration can be done by clicking on the relevant
              section in the Application, and the registration can be proceeded
              with in accordance with the steps directed therein. In order to
              register Yourself, You must create Your unique login name and
              password after submission of certain information as may be required
              therein. Further, You may be asked to submit certain documents for
              verification of Your age and authenticity of other details as part
              of KYC (Know Your Client) process. Failure to furnish any such
              information or documents, or in case any information or document
              submitted by You is found to be false, Your Registration along with
              Your account associated with such registration may be suspended or
              terminated by Us, at Our discretion, without any prior notice. We
              shall not be liable towards, or be obligated towards, in any manner,
              for any loss incurred owing to account so suspended or terminated.
            </li>
            <li>
              You acknowledge and agree that completing KYC process as required by
              the Company shall be a pre-condition to complete registration
              process on Our Application. Further, You agree that the Company may
              require You to re-verify Your credentials by completing the KYC
              process on periodical basis. &nbsp;
            </li>
            <li >
              You understand and agree that for the purpose of Registration and
              enrollment on the Application, We may require You to submit certain
              specified information and documents including Your name, email
              address, mobile number, password as created for Registration
              purposes, and/or other third-party credentials through which You
              proceed for Registration and as allowed by Us on the
              Application.&nbsp;
            </li>
            <li >
              We may require You to furnish the documents and information as
              referred to hereinabove in the form of soft copy uploaded on the
              Application, and also by way of submission of signed hard copy of
              the same at a pre-determined address. You may be required to comply
              with the attestation requirement (including by way of
              self-attestation) for submission of hard copies. In the event where
              any discrepancy is found between the credentials specified on the
              Application and in the hard copy submitted, the Registration request
              may be rejected by Us with no consequential liability or obligation
              of any nature upon Us.
            </li>
            <li >
              You agree that for the purpose of Registration, and to enable You to
              carry transactions through the Application, We may submit and
              forward the documents and information furnished by You to AMCs
              and/or their vendors and/or other regulatory authorities.
            </li>
            <li >
              You acknowledge and agree that We are not obligated to cross-check
              or verify any of Your information and hence We do not take any
              responsibility for any consequences or outcomes that may result from
              You providing false, incorrect, incomplete, concealed or irrelevant
              information to Us. Therefore, You are required to provide Us with
              complete and authentic information to the best of Your knowledge to
              access the Services. Also, You should not be a politically exposed
              person or convicted of any crimes. You should not be on the UN
              sanction list/ work for UN sanction list.
            </li>
            <li >
              For the purpose of KYC, We may make use of an third-party services,
              whether integrated in the Application or otherwise, You understand
              and agree that We shall not be responsible towards any act or
              conduct of such third-party service provider. You shall review the
              terms of such third-party service provider directly, and
              accordingly, make decisions with respect to proceeding with KYC.
            </li>
            <li>
              After the Registration is complete, any change in the information
              already submitted by You shall be affected only upon a written
              request in this behalf, and under Your signatures, and in compliance
              with the regulatory provisions under the Applicable Laws.
            </li>
            <li>
              By registering Yourself and by accessing the Services, You hereby
              agree to not sharing or distributing Your password and/or other
              account information to any third-person, that is to say, only You
              shall be authorized to access Your account and the Services. We
              shall not be responsible towards any loss or damage that may occur
              owing to such sharing of password and other account information. You
              shall solely be responsible towards all the activities on the
              Application through Your Account. You understand and agree that We
              shall not incur any liability for information provided by You to
              anyone which may result in Your account on the Application being
              exposed or misused by any other person or any other harm or loss
              being suffered by You.&nbsp;
            </li>
            <li >
              You undertake not to impersonate another person or user of Our
              Application, or attempt to get a password or any other account
              information not belonging to You for accessing Our Application. We
              may initiate appropriate legal proceedings against You as well as
              notifying the relevant regulatory or law enforcement authorities
              where appropriate, apart from suspending or terminating Your
              privileges for the use of the Application, in the event, We have
              reasonable grounds to believe that You have violated Your
              undertaking herein in any manner. Any act in violation of this
              clause by You shall be considered to be a material violation by You.
            </li>
          </ol>
        </div>
        <br />
        <div>
          <p >
            <strong>SERVICES</strong>
          </p>
        </div>
        <div >
          <p >
            <strong>GAP Product terms</strong>
          </p>
        </div>
        <div>
          You understand that if You purchase any GAP (Gold Accumulation Plan) Products
          through Our platform, it shall be considered that You have consented to the
          terms as provided in here, which may be amended,
          modified, or substituted from time to time and such change shall be at the
          sole discretion of the Company. It shall be Your obligation to take note of
          any change to these terms before using the platform and before purchasing any
          GAP Products. In case You do not accept the terms as provided in the link
          above, You must cease from accessing and transacting in any GAP Products on
          Our platform immediately. In case You continue to access and/or transact in
          GAP Products on the platform, it shall be deemed acceptance of You and that
          You have agreed to the terms as provided here, and You
          shall be bound by the same.
        </div>
        <br />
        <div>
          <p >
            <strong>EXCLUDED JURISDICTIONS</strong>
          </p>
        </div>
        <div >
          <ol >
            <li >
              The offering of Services by Us shall, at all time, be in complete
              compliance with the applicable laws. You understand and agree that
              the Services may not be available, or be available with restricted
              access and capabilities, to the persons situated and/or based
              outside of India.
            </li>
            <li >
              It may also be noted that the Services are being offered only in
              India and You shall only access the Services form India, while being
              in compliance with applicable laws.
            </li>
            <li >
              The Company may, in compliance with the applicable laws, may permit
              persons situated outside of India for accessing the Services.
            </li>
          </ol>
        </div>
        <br />
        <div>
          <p >
            <strong>PAYMENTS</strong>
          </p>
        </div>
        <div >
          <ol >
            <li >
              You understand and agree that We may make use of any third-party
              payment channels and gateways for payment processing, whether
              integrated in the Application or otherwise, You understand and agree
              that We shall not be responsible towards the behavior and working of
              such third-party payment channels and gateways. You shall review the
              terms of such third-party payment channels and gateways directly,
              and accordingly, make decisions with respect to proceeding with any
              payments on Application. Any payment shall be subject to payment
              authentication mechanism as may be applicable from time to time.
            </li>
            <li >
              You understand and agree that there are inherent risks involved in
              online payments, including but not limited to misuse of passwords,
              internet frauds, mistakes and errors, technological risks, and
              other, and the Company shall NOT be responsible towards the same in
              any manner. You may proceed with any payments on the Application at
              Your sole discretion.
            </li>
            <li >
              You understand and agree that the Company may charge fee and/charges
              for the Services provided through the Application, and such fee
              and/or charges shall be charged at the terms specified on the
              Application. The Company may modify and update such terms, fee
              and/or charges from time to time at its own discretion without any
              prior intimation to You, and You shall have no objection thereto and
              be bound by the same.
            </li>
            <li >
              Any payments made, and the transactions conducted, through the
              Application shall be subject to taxes as may be applicable under
              applicable laws in force.
            </li>
            <li >
              YOU HEREBY UNDERTAKE AND DECLARE THAT ANY AMOUNT TRANSACTED BY YOU
              THROUGH THE APPLICATION SHALL BELONG TO YOU AND SHALL BE DERIVED
              FROM THE LEGITIMATE SOURCES AND SHALL NOT CONTRAVENE IN ANY MANNER,
              DIRECTLY OR INDIRECTLY, ANY LAW IN FORCE, INCLUDING CONCERNING ANY
              LAWS GOVERNING FOREIGN EXCHANGE, MONEY LAUNDERING, COUNTER
              TERRORISM, CUSTOMER IDENTIFICATION CHECK, AND OTHERS, WHETHER IN
              INDIA OR OTHERWISE.
            </li>
          </ol>
        </div>
        <br />
        <div>
          <p >
            <strong>INTELLECTUAL PROPERTY</strong>
          </p>
        </div>
        <p >
          The Company holds absolute ownership of all the tangible or intangible
          rights with respect to the Application and the Services offered, any
          promotional material and the technology involved, such right being
          referred to as the &ldquo;<strong>Intellectual Property Rights</strong>&rdquo;. Notwithstanding, any third-party
          content offered on the
          Application as part of Services shall be rights of such third-party. By
          allowing the access to the Services through Application, We are only
          permitting You to avail the Services in accordance with the prescribed
          Other Terms, and We are in no manner permitting You to use the
          Intellectual Property Rights including by way of copying, reproducing or
          transmitting any content, material or media in any manner.
        </p>
        <br />
        <div >
          <p >
            <strong>TECHNICAL FAILURES</strong>
          </p>
        </div>
        <div >
          <ol >
            <li >
              The Company uses information technology-based software to offer the
              Services and operate the Application. External internet networks and
              connections are used to connect the Application (operated through
              Your mobile devices and hardware) to Our servers. We do not hold any
              liability towards any disruptions in such internet networks and
              connections including any errors, network disconnection or
              interruptions in communication and shall not be liable towards any
              loss incurred to You owing to such disruptions. Similarly, We shall
              not liable towards any loss arising out of any technical failures,
              server crashes, breakdowns, software defects, disruption or
              malfunction of Service owing to Your fault or failure of Your mobile
              device or hardware.
            </li>
            <li>
              In the event of any technical failures, server crashes, breakdowns,
              software defects, disruption or malfunction of service attributed to
              Us while any transaction is in progress, You acknowledge and
              understand that We have no control over these factors. Further, You
              hereby expressly acknowledge that We shall not be responsible for
              any such interruption in Services, and You take full responsibility
              for any risk of loss owing to Service interruptions for any such
              reason.
            </li>
          </ol>
        </div>
        <br />
        <div>
          <p >
            <strong>REPRESENTATION BY USER</strong>
          </p>
        </div>
        <p>
          You, by accessing the Services and Application and agreeing to these
          Term, represent as follows:
        </p>
        <br />
        <div>
          <ol >
            <li>
              That You have capacity to enter into, execute and deliver this
              agreement, and to exercise Your rights and perform Your obligations
              as contemplated hereby, in accordance with the provisions of Indian
              Contact Act, 1872 and other applicable laws;
            </li>
            <li>
              That this agreement constitutes the legal, valid and binding
              obligation on You, and shall be enforceable against You in
              accordance with these Terms, except as such enforceability may be
              limited by applicable bankruptcy, insolvency, re-organization,
              moratorium or similar laws affecting Our rights generally;
            </li>
            <li>
              That consummation of the Terms contemplated hereby will not (i)
              conflict with or result in any breach or violation of any of the
              terms and conditions of, or constitute (or with notice or lapse of
              time or both constitute) a default under, any instrument, contract
              or other agreement to which You are a party or You are bound; (ii)
              violate any order, judgment or decree against, or binding upon, You;
            </li>
            <li>
              That any information and documents reasonably required from You to
              comply with any applicable anti-money laundering or
              counter-terrorism financing laws including any applicable laws
              imposing know your customer or other identification checks or
              procedures that the Company may require to comply with in respect of
              this agreement shall be provided by You.
            </li>
          </ol>
        </div>
        <br />
        <div>
          <p>
            <strong>WARRANTY</strong>
          </p>
        </div>
        <p>
          We are committed to providing updated, accurate, complete and reliable
          information by publishing it on the Application. However, We disclaim
          any and all warranties, expressed and implied, with respect to the
          Services or Application or any of its content. All the information and
          content with respect to the Services and Application is provided to You
          on &lsquo;As Is&rsquo; basis, and You agree to access the Services and
          Application at Your sole risk. We do not represent the suitability of
          the information published in documents or any other information that is
          updated on Our Application. We hereby solemnly disclaim and discard all
          warranties about any information as well as all implied warranties and
          conditions of Our merchantability, title and non-infringement,
          appropriateness to any particular purpose, and accuracy of Our
          Application and Services. We do not give any warranty that the servers
          used to offer the Services and run the Application will be error-free,
          uninterrupted, or that they will not get affected by bugs, defects or
          viruses of any kind. Hence, We reserve the right to determine, at Our
          sole discretion, whether any issue at Our end is responsible for any
          error or disruption or malfunction. We may limit Your access to Services
          or Application, if We opine that You are responsible for intentionally
          causing any malfunction or disruption. You may note that no person is
          authorized to extend or revise the warranty stated herein.
        </p>
        <br />
        <div>
          <p>
            <strong>SOFTWARE USAGE</strong>
          </p>
        </div>
        <p >
          It is strictly forbidden for anyone to use Our Services or Application
          for any commercial purposes. Our Application is meant only for accessing
          Services. You shall not attempt to manipulate, decode, modify,
          disassemble, decompile, reverse or re-engineer Our Application or its
          software in any manner. Please note that Use of Bots is prohibited. Any
          attempt to use artificial intelligence that includes, but is not limited
          to, robots, in connection with our Application is strictly forbidden.
          You are authorized to access the Services solely through the Application
          while using the user interface contains therein. Any act in violation of
          this clause by You shall be considered to be a material violation by You
        </p>
        <br />
        <div>
          <p>
            <strong>FAIR USAGE</strong>
          </p>
        </div>
        <div>
          <ol>
            <li>
              We take the fair usage of the Application very seriously. In order
              to prevent any form of fraud or unfair means concerning the
              Services, all user actions including monetary transactions are
              continuously monitored to ensure a safe, legal and fair environment
              for all Our users. Hence, all user actions are monitored and
              recorded as mentioned above.
            </li>
            <li>
              Any detection of a breach of Our fair usage policy shall result in
              prompt and serious action by Us against the suspected user, in the
              manner detailed herein below:

              <ul>
                <li>
                  <p>
                    <u>Anti-Cheating</u><br />
                    You undertake that You Yourself access the Services for which You have
                    registered and not use any form of external assistance to such access.
                    You shall not add any unauthorized components and not create nor use any
                    cheats, exploits, bots, hacks or any other third-party software designed
                    to modify the Application. You also undertake that You will not use any
                    third-party software that intercepts, mines or in any way collects
                    information from or through the Application or through any of Our
                    Services. Any attempt to employ any such external assistance is strictly
                    prohibited. Any act in violation of this clause by You shall be deemed
                    to be a material violation by You.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Money Laundering</u><br />
                    You are prohibited from doing any activity on the Application that may
                    be construed as money laundering within the meaning of applicable laws
                    in India or outside. Any act in violation of this clause by You shall be
                    deemed to be a material violation by Us.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Anti-Spamming</u><br />
                    Sending SPAM emails or any other form of unsolicited communication for
                    obtaining registrations on the Application to benefit from any
                    promotional program or for any other purpose is strictly prohibited.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Multiple IDs</u><br />
                    Your registration on the Application is restricted to a single user
                    account, which will be used by You to avail Yourself of the Services
                    provided on the Application. You are prohibited from creating or using
                    multiple user IDs for accessing Our Services.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              You shall not create a login name or password, or
              upload/distribute/transmit/publish/post content through or on the
              Application or through any service/facility including any messaging
              facility provided by the Application which:
              <ol>
                <li>
                  is libelous, defamatory, obscene, intimidating, invasive of privacy,
                  abusive, illegal, harassing;
                </li>
                <li>
                  contains expressions of hatred, expressions hurting religious
                  sentiments, or expressions related to racial discrimination or
                  pornography;
                </li>
                <li>
                  is in any way objectionable or undesirable (whether or not unlawful);
                </li>
                <li>
                  is or can be construed as an incitement to commit a criminal offence;
                </li>
                <li>violates the rights of any person;</li>
                <li>is aimed at soliciting donations or other form of help;</li>
                <li>
                  violates the intellectual property of any person and/or any legally
                  formed entity;
                </li>
                <li>
                  disparage in any manner to Us or any of Our subsidiaries, affiliates,
                  licensors, associates, partners, sponsors, products, services, or
                  Application;
                </li>
                <li>promotes a competing service or product; or</li>
                <li>violates any applicable laws.</li>
              </ol>
            </li>
            <li>
              In the event that We determine that the user name chosen and created
              by You is indecent, objectionable, offensive or undesirable in any
              way, We shall notify You about it and You shall promptly provide Us
              with an alternate username so that We can change Your existing
              username to the new name provided by You. If You fail to provide an
              alternate username, We reserve the right to either permanently
              suspend Your user account or reactivate Your user account only after
              You have provided a different, acceptable username.
            </li>
            <li>
              You shall not host, intercept, emulate or redirect proprietary
              communication protocols, if any, used by the Application, regardless
              of the method used, including protocol emulation, reverse
              engineering, nor modify the Application or any files that are part
              of the Application. Any act in violation of this clause by You shall
              be considered to be a material violation by You.
            </li>
            <li>
              You shall not frame the Application. You may not post or upload
              editorial comments, commercial material or any information on the
              Application, alter or modify content on the Application, or remove,
              obliterate or obstruct any proprietary notices or labels.
            </li>
            <li>
              You shall not use the Services on the Application for any commercial
              purposes including, but not limited to, use in a cyber cafe as a
              computer gaming center, network play over the Internet or through
              gaming networks or a connection to an unauthorized server that
              copies the gaming experience on the Application. Any act in
              violation of this clause by You shall be considered to be a material
              violation by You.
            </li>
            <li>
              You shall not upload, distribute or publish through the Application
              any content that may contain viruses or computer contaminants (as
              defined in the Information Technology Act, 2000 or such other laws
              in force in India at the relevant time) which may interrupt,
              destroy, limit the functionality of or disrupt any software,
              hardware or other equipment belonging to Us or that aids in
              providing the services offered by Us. You shall not disseminate or
              upload viruses, programs, or software to the Application whether it
              is harmful to the Application or not. Any act in violation of this
              clause by You shall be considered to be a material violation by You.
            </li>
            <li>
              Any form of fraudulent activity including attempting to use or using
              any other person&rsquo;s credit card(s), debit cards, net-banking
              usernames, passwords, authorization codes, prepaid real money cards,
              and mobile phones for depositing money into Your account is strictly
              prohibited. Any act in violation of this clause by You shall be
              deemed to be a material violation by Us.
            </li>
            <li>
              Accessing or attempting to access Our Services through someone
              else&rsquo;s user account is strictly prohibited.
            </li>
            <li>
              You shall not post any material or comment on any media available
              for public access that, in Our sole discretion, is defamatory or
              detrimental to Our business interests, notwithstanding the fact that
              such media is or is not not owned or controlled by Us. In addition
              to any other action that We may take pursuant to the provision
              hereof, We reserve the right to remove any and all material or
              comments posted by You and restrict Your access to any media
              available for public access that is either controlled or moderated
              by Us, when in Our sole opinion, any such material or comments
              posted by You is defamatory or detrimental to Our business
              interests.
            </li>
          </ol>
        </div>
        <br />
        <div>
          <p>
            <strong>Breach and Consequences</strong>
          </p>
        </div>
        <p>
          If We have evidence, through Our investigations or reasonable belief, of
          a breach of these Terms by You and/or that Your continued access to Our
          Application is detrimental to the interests of Company or any other
          users or the general public, then We reserve the right to take any or
          all of the actions stated herein below at Our sole discretion:
        </p>
        <div>
          <ol>
            <li>
              Permanently suspend or deregister Your user account on Our
              Application;
            </li>
            <li>
              Demand and order damages for breach of these Terms and take required
              civil and criminal actions to recover damages;
            </li>
            <li>
              Initiate steps of prosecution for damages and violation that is
              equivalent to offences in law;
            </li>
            <li>
              Bar You from registering on Application in the future;
            </li>
            <li>
              The action taken by Us will be solely due to Your breach of Our
              Terms; the action shall be final and decisive that will be binding
              on You. Any action taken by Us will be without any prejudice to Our
              other rights and remedies that are mentioned and available in law or
              equity.
            </li>
            <li>
              Without prejudice to the foregoing, You agree that You shall
              be obligated to compensate the Company for any loss or damage
              incurred to the Company as a result of breach of any of these Terms.
              Additionally, in case of any material violation, as referred to in
              these Terms, You shall be liable to compensate the Company with an
              amount of INR 25,00,00,000 (Rupees Twenty Five
              Crores)<strong>&nbsp;</strong>or the amount of actual loss and
              damages, as may be quantified by Us, incurred to the Company,
              whichever is higher.&nbsp;
            </li>
          </ol>
        </div>
        <br />
        <div>
          <p>
            <strong>CONTENT AND CONTRIBUTION</strong>
          </p>
        </div>
        <div >
          <ol>
            <li>
              All the online content, materials and information on Our Application
              are collectively referred to as &lsquo;Content&rsquo;. It may
              include a wide range of online items such as images, designs,
              multimedia clips, graphics, logos, information, text content,
              software, animation, marks, hyperlinks, and pictures. The Content,
              whether or not belonging to Us, are thus protected and secured by
              applicable intellectual property rights. Additionally, We shall be
              free to record and use, in any manner whatsoever, all chat content,
              images, emails, and recommendations sent by any member or user on
              Our Application, as they appear on the Application and form part of
              the Content.
            </li>
            <li>
              It is informed that Our Application may contain information about
              third-parties and may comprise of hyperlinks within them. You shall
              use or access such third-party content or hyperlink at Your sole
              risk. We shall not be responsible in any way for the implied
              warranty about the quality, integrity or accuracy of such
              third-party content. You expressly acknowledge that We shall not be
              responsible for any content belonging to any third-party, such
              third-party content appearing on Our Application shall not be deemed
              as endorsement towards such third-party content by Us.
            </li>
            <li>
              You agree to not to post any content on the Application that does
              not belong to You, or with regards to which You do not have any
              rights or permission to post. You agree that You shall be solely be
              responsible towards any unlawful posting and shall be obligated to
              compensate Us for any loss or damage that We may have to bear on
              account of You posting any content unlawfully.
            </li>
          </ol>
        </div>
        <br />
        <div>
          <p>
            <strong>AMENDMENT</strong>
          </p>
        </div>
        <p>
          In the event any provision or part-provision of these Terms is or
          becomes invalid, illegal or unenforceable, it shall be deemed modified
          to the maximum extent necessary to make it valid, legal and enforceable.
          In case such modification is not possible, the relevant provision or
          part-provision shall be deemed deleted. Any modification to or deletion
          of a provision or part-provision shall not affect the validity and
          enforceability of the rest of these Terms.
        </p>
        <br />
        <div>
          <p>
            <strong>LIMITATION OF LIABILITY</strong>
          </p>
        </div>
        <p>
          You understand and agree that the Company shall not be liable towards
          any direct or indirect damages, resulting from use of or inability to
          use the Service, even if We have been advised or become aware of the
          possibility of such damages or loss or that such loss was foreseeable.
          Such damages may include injury, claim, loss of data, loss of income,
          loss of profit or loss of opportunity, loss of or damage to property,
          damages for monetary loss, goodwill, use, data or other intangible loss
          (even if the Company has been advised of the possibility of such
          damages). You agree to indemnify Us against any claims in respect of any
          such matters.
        </p>
        <br />
        <div>
          <p>
            <strong>ASSIGNMENT</strong>
          </p>
        </div>
        <p >
          Your rights and obligations under this agreement is personal to You and
          shall not be assigned to any other party or person. The Company may, at
          its sole discretion, assign, transfer or novate this agreement or any of
          its rights and/or obligations under this agreement to any person,
          including their affiliate.
        </p>
        <br />
        <div >
          <p>
            <strong>RELATIONSHIP BETWEEN PARTIES</strong>
          </p>
        </div>
        <p>
          None of the provisions of this agreement shall be deemed to constitute a
          partnership between the Company and User, and no party shall have any
          authority to bind the other parties otherwise than under this agreement
          or shall be deemed to be the agent of other in any way.
        </p>
        <br />
        <div>
          <p>
            <strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong>
          </p>
        </div>
        <p>
          All disputes and differences arising from this agreement shall be
          referred to the Sole Arbitrator appointed by Company and User on mutual
          agreement, and such reference shall all time be governed by the
          provisions of the Arbitration and Conciliations Act, 1996. The decision
          of the Sole Arbitrator shall be final and binding on all the parties.
          The arbitration shall be conducted in English, and the venue for
          arbitration shall be Haryana, India. Additionally, the Parties shall be
          entitled to seek specific performance of the terms hereof. Each party
          herein irrevocably agree that the courts of Haryana, India shall have
          exclusive jurisdiction to settle any dispute or claim arising out of or
          in connection with this Agreement or its subject matter or formation
          (including non-contractual disputes or claims).
        </p>
        <br />
        <div>
          <p >
            <strong>CONTACT</strong>
          </p>
        </div>
        <p>
          For any queries, assistance or suggestions, You may freely contact Us
          anytime through Our customer care services at <b>admin@wyngmate.com</b>.
          In the event You suspect any unauthorized use of Your login
          credentials and any other information, You may inform Us immediately
          requesting suitable actions. You hereby agree and permit our customer
          care services to contact You as and when needed with respect to the
          Services, promotional activities, and for other communications. You
          hereby agree and permit that any and all communications between You and
          our customer care services may be recorded for quality and other
          purposes.&nbsp;
        </p>
      </div>
      <Footer />
    </div>
  )
}

export default Terms;
