import React from 'react';
import BenefitsSection from '../BenefitsSection/BenefitsSection';
// import IntegrationSection from '../Document/IntegrationSection';
// import HowSection from '../HowSection/HowSection';
import LandingPage from '../LandingPage/LandingPage';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/NavBar';
import CustomersSection from '../Pricing/CustomersSection';
import Featuers from '../Features/Featuers';
import ReactGA from "react-ga4";

const Home = () => {
  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Web Home Page" });
  
  return (
    <div>
      <Navbar />
      <LandingPage />
      <BenefitsSection />
      <Featuers id="features"/>
      <br/>
      <div className="theme-bg-light">
        <CustomersSection />
      </div>
      {/* <HowSection />
          <IntegrationSection /> */}
      <Footer />
    </div>
  )
}

export default Home;
