import { toast } from "react-toastify";

export const showToastMessage = (
  message,
  isSuccess = true,
  autoClose = 3000
) => {
  if (isSuccess) {
      toast.success(message, {
          hideProgressBar: true,
          autoClose: autoClose,
      });
  } else {
      toast.error(message, {
          hideProgressBar: true,
          autoClose: autoClose,
          closeOnClick: true,
      });
  }
};

export const showToastInfoMessage = (
  message,
  autoClose = 3000
) => {
  toast.info(message, {
      hideProgressBar: true,
      autoClose: autoClose,
      closeOnClick: true
  });
};

export const showErrorMessage = (error) => {
  console.error(error);
  if (error && error.response?.data?.error?.message) {
    showToastMessage(error.response?.data?.error?.message, false);
  } else if (error && error.response?.data?.message) {
    showToastMessage(error.response?.data?.message, false);
  } else if (error && error.response?.data?.error) {
    showToastMessage(error.response?.data?.error, false);
  } else if (error && error?.message) {
    showToastMessage(error.message, false);
  }else{
    showToastMessage(error, false);
  }
}
