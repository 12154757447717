import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const LoadingSpnner = ({divWidth}) => {
    return (
        <div style={{width:divWidth?divWidth:'100%',display:'flex',justifyContent:'center'}}>
            <ColorRing
                height="60"
                width="60"
                colors={["#06B1AB", "#06B1AB", "#06B1AB", "#06B1AB", "#06B1AB"]}
                ariaLabel="circles-loading"
                wrapperStyle={{ justifyContent: 'center' }}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}

export default LoadingSpnner;