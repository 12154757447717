import React from 'react'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/NavBar';
import styles from "./styles.module.css";
import ReactGA from "react-ga4";

const Privacy = () => {

  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Web Privacy Page" });

  return (
    <div>
      <Navbar />
      <div className="container">
        <h1 className={`page-heading ${styles.heading}`}>PRIVACY POLICY</h1>
        <div>
          <p>
            This privacy policy <strong>(“Policy”)</strong> forms an integral part
            of Our Terms of Use <strong>(“Terms”)</strong>. You must read,
            understand and agree to, and be bound by, this Policy. You must not
            access the Website (as defined in the Terms) or Information (as
            defined in the Terms), in any manner, in case You do not expressly
            accept this Policy in its entirety.
          </p>
          <p>
            This Policy explains how ‘WyngMate’ a
            company incorporated under the Companies Act, 2013 and having its
            registered office at C-258 2nd Floor, Mianwali Colony, New Railway Road, Gurugram
            Haryana, 122005, corporate office at 434, B1, Spaze iTech Park, Sector 49,
            Gurugram, Haryana 122018, India and/or its affiliates (hereinafter referred to as
            <strong>"We"</strong> or <strong>"Us" </strong>or
            <strong>"Our"</strong> or(<strong> “Company”</strong>) collects,
            protects, uses, discloses and protects the Personal Information (as
            defined hereinafter) and Sensitive Personal Information (as defined
            hereinafter), obtained explicitly through the Website, or collected
            from You through Your direct submission or disclosure while accessing
            the Website or Information, and also other information collected
            automatically without direct submission by You as a user of Website
            (hereinafter referred to as <strong>“You”, “Your”, “User”</strong>).
            This Policy governs all the Information and content, either as a
            subscription offering or otherwise, We offer directly or indirectly
            through the Website.
          </p>
          <p>
            By accessing the Website, You agree to this Policy with respect to the
            collection, processing, and use of Your data or information, including
            Personal Information (as defined hereinafter) and Sensitive Personal
            Information (as defined hereinafter), by Us. Please do not provide
            consent towards acceptance of cookies and privacy acceptance at the
            banner that appears when You first visit the Website, or access the
            Website, or register on the Website, if You do not wish to share the
            mandatory personal information and gathered upon Your access to the
            Website or requested at the time of Registration (as defined in the
            Terms), or if You do not want Us to collect certain data or
            information automatically when You access the Website. You may note
            that Registration may not be proceeded with until information
            requested in the non-optional fields is provided.
          </p>
          <h4> 1. Collection of Information</h4>
          <p>
            We collect certain information from and about its users directly from
            the user, and amongst other means, from server cookies and other data
            files.
          </p>
          <p>
            To prevent unauthorized access, maintain data accuracy, and ensure the
            correct use of information, We put in place appropriate physical,
            electronic, and managerial procedures to safeguard and secure the
            information collected online.
          </p>
          <p>
            In order to allow access and to gain authorized access to the contents
            on the Website, We may obtain certain information from the user, such
            as contact information (full name, address), telephone number, e-mail
            address, and others through the process of Registration.
          </p>
          <p>
            <strong>1.1.</strong> “Personal Information” is information that
            relates to a user and which, either directly or indirectly, in
            combination with other information available or likely to be available
            with the Company, is capable of identifying such person.
          </p>
          <p>
            Any of the following data may be collected as Personal Information by
            the Company:
          </p>

          <ul>
            <li>Name</li>
            <li>Date of birth</li>
            <li>Telephone number</li>
            <li>Email address</li>
            <li>Postal/Contact address</li>
            <li>The date and the time of Your accessing the Website</li>
            <li>Any communication, in any manner, between You and Us</li>
            <li>
              Any other information that is defined as “personal information” in
              law
            </li>
          </ul>
          <p>
            <strong>1.2.</strong> “Sensitive Personal Information” shall include
            account passwords, and any other information that is defined as
            “Sensitive personal data or information” in law
          </p>
          <p>
            <strong>1.3.</strong> Additionally, the following information may be
            automatically collected, when You visit and use the Website:
          </p>
          <ul>
            <li>The type of device and hardware You use</li>
            <li>Your IP address</li>
            <li>Your browser type</li>
            <li>Type of Your computer and mobile systems and its language</li>
            <li>
              Information about Your use of the Website (when, how, how long,
              account used to log in, features used while logged in or otherwise
              status and quantum of devices operation mode and others)
            </li>
            <li>clicks</li>
            <li>GPS and/or WIFI Position</li>
            <li>Your used language etc</li>
          </ul>
          <p>
            The information We collect helps Us administer the Website, analyze
            its usage, protect the Website and its content from inappropriate use,
            and improve the user experience.
          </p>
          <h4>2. Cookie Policy</h4>
          <h5>2.1. What are Cookies?</h5>
          <p>
            A ‘cookie’ is a small piece of data send to a user’s computer (through
            a web-browser) by a web-server (website) to store certain information
            about a user when a website is accessed by such user. Such a cookie
            may contain information about a user’s visit to the website, including
            information which a user may have contributed voluntarily to the
            website. Our Website may store and use ‘cookies’ to collect and
            receive certain information. Cookies are used for User administration,
            research and development, technical administration of Website, and
            also to track and administer traffic on the Website. Our Website may
            collect and use the data contained in the cookies. The information in
            the cookies help provide additional functionality to the Website, such
            as a cookies might help in remembering your login credentials and
            obviates the need to re-enter again every time you access the Website.
            The cookies may help in evaluating browsing habits and usage behavior,
            areas of the Website that are visited. We routinely use such
            information in the aggregate in order to administer and improve the
            Website and ensure security, and for other purposes as may be
            specified hereof.
          </p>
          <p>
            Cookies are sent to a user's web browser from a web server by way of
            small amounts of data that is eventually stored on a user's device. A
            user has the option to disable the cookies through the appropriate
            functioning of its browsers. Cookies are classified based on
            functionality, each of such classifications of cookies has its
            function.
          </p>
          <p>
            The cookies used by Us on the Website can either be of the two types:
          </p>
          <ol>
            <li>
              <span> Cookies </span>– These are the cookies that are sent and set
              directly by the Website You are visiting, and often associated
              directly with the functioning of the Website and its features.
            </li>
            <li>
              <span>Third-Party Cookies</span> – These are the cookies that are
              sent and set by the domains other than the Website You are visiting,
              and are often targeted for specific purposes such as visitor
              analytics and others.
            </li>
          </ol>
          <p>
            The Cookies used by Us are more specifically categorized based on
            functionality, each of such category of cookies is enlisted
            hereinbelow and has its specified function:
          </p>
          <h5>2.2. Types of Cookies Used</h5>
          <ol>
            <li>
              <span>Strictly Necessary Cookies</span>: These cookies are necessary
              for You to visit the Website and access all its features. These
              cookies are essential for the functioning of the Website. In the
              event, You decide to block such cookies, You may not be able to
              access all or any parts of the Website. You can use your browser to
              block, or alert you about, such cookies. Such cookies do not store
              any personal identifiable information. These are the First-Party
              Cookies and are directly attributed to Us.
            </li>
            <li>
              <span>Functionality Cookies</span>: These cookies enhance
              functionality of the Website by bringing personalization and
              remembering Your previous choices and selections on the Website,
              such as remembering Your login credentials so that You might not
              need to enter the same everytime You visit the Website. These can
              either be First-Party or Third-Party cookies.
            </li>
            <li>
              <span>Performance Cookies</span>: These cookies allows us to count
              visits on the Website, and also to measure the traffic sources. The
              cookies enables us to improve the performance of the Website by
              gathering the usage analytics, including evaluating the most popular
              and least popular pages. The information collected by the cookies
              does not contain any personal identifiable information, and is
              aggregated and anonymous. These can either be First-Party or
              Third-Party cookies.
            </li>
          </ol>
          <h5>2.3. Your Choices</h5>
          <p>
            You have the right to control and manage the cookies on your device.
            You have the right to control the cookies in either or both of the
            following manner:
          </p>
          <ol>
            <li>
              <strong>Cookie Settings.</strong> The Cookie Settings option may be
              accessed be either through the banner that appears when you first
              visit the Website, or by accessing the same at the footer of any
              webpage. The Cookie Settings allows you to see the cookies generated
              under each of the categories mentioned above. You have the right to
              enable or disable any of the cookie categories by making a relevant
              selection within the Cookie Settings. It may be noted that disabling
              a cookie category may have a corresponding effect on the
              functionality and performance of the Website, and resultingly, You
              may not be able to access certain features or information on the
              Website.
            </li>
            <li>
              <strong>Web browser.</strong> Most web browser allows you to see the
              cookies stored in your device. You have the option to delete all or
              any of them. Additionally, based on your web browser, You might have
              the option to block cookies from a specific website, or to block
              third-party cookies, deleting all cookies upon closing your web
              browser, and others. Each web browser has different functionality in
              this regard therefore, it is advised to check the relevant user
              guide. Also, it is recommended to always use the latest version of
              the web browser.
            </li>
          </ol>
          <h4>3. How and why do We use Your personal information
          </h4>
          <p>
            We use Your information for the following purposes:
          </p>
          <ul>
            <li>To enable You to access the Website and Information</li>
            <li>To personalize Your experience on the Website</li>
            <li>To provide, maintain and improve the Website</li>
            <li>To inform You about new versions or features of the Website</li>
            <li>
              For authentication and protection against identity fraud and
              identity theft
            </li>
            <li>
              To monitor and analyze trends, usage and activities in connection
              with the Website
            </li>
            <li>
              To send You emails related to Your use of the Website in general,
              including alerts, and also to send other communications and
              response
            </li>
            <li>
              To send You technical notices, updates, security alerts and support
              and administrative messages
            </li>
            <li>For research and analytics</li>
            <li>For enforcing the Terms</li>
            <li>For other purposes as We may notify You from time to time</li>
            <li>Any other permitted use</li>
          </ul>
          <h4>4. Information Sharing</h4>
          <p>
            We may share Your personal information collected for the following
            purposes:
          </p>
          <ul>
            <li>
              Legal Purposes: We may share Your information to the law enforcement
              and other government agencies, courts and other bodies on their
              legal requests to comply with the law and aid to the ongoing and
              impending legal proceedings. We may also use Your information when
              it is necessary to investigate, prevent, or take action regarding
              possible illegal activities or to comply with legal process or to
              comply with any law in force or to address threats to the physical
              safety of any person or to safeguard Our rights and the rights of
              users, or the public at large
            </li>
            <li>to enforce its Terms and this Privacy Policy</li>
            <li>for prevention of fraud</li>
            <li>
              for issues involving information security, or to protect Your
              rights, or rights of Company, or rights of the general public
            </li>
            <li>To comply with the applicable laws and legal requirement</li>
          </ul>
          <h4>5. Aggregate Information:</h4>
          <p>
            We may collect statistical information about how both unregistered and
            registered users, collectively, use the Website (“Aggregate
            Information”). Some of this information may be derived from analysis
            of Personal Information, and others through cookies. This statistical
            information is not Personal Information and cannot be tied back to
            You, Your account.
          </p>
          <h4>6. Consent</h4>
          <p>
            All users of Our Website must consent to the use of their information
            collected by Us for the purposes stated in this Policy. We restrict
            access to such information and only allow access to those who need to
            know such information in order to provide You access to the Website.
          </p>
          <p>
            Users are entitled to withdraw consent to the use of their information
            by emailing a specific request to Us. Withdrawal of consent to use all
            or any part of information may result in the immediate withdrawal of
            any rights provided to the User, including access to the Website and
            Information provided by Us. Withdrawal of consent by users shall not
            restrict the right of Use to use personal data for the analysis of
            user data.
          </p>
          <u>Consent to email communication </u>
          <p>
            When You register Your email address with Us, either through
            Registration or otherwise, You agree to receive email communication
            from Us, entities specifically authorized by Us, and other users.
          </p>
          <h4>7. Data Security</h4>
          <p>
            We take appropriate security measures to protect user data from
            unauthorized access and its unauthorized alteration, disclosure, or
            destruction. These measures include internal reviews of Our data
            collection, storage, and processing practices, and security measures,
            including appropriate encryption and physical security measures to
            guard against unauthorized access to systems where We store users’
            personal information. We have a comprehensive information security
            program and information security policies that contain managerial,
            technical, operational, and physical security control measures adopted
            by Us for the protection of information collected.
          </p>
          <p>
            Information gathered by Us is stored securely using several
            information security applications. However, security is always
            relative and We cannot guarantee that the security measures are
            absolute and cannot be breached. Data transmitted over the internet is
            inherently exposed to security risks or threats. Therefore, We cannot
            guarantee any security for such information in the course of
            transmission through the internet infrastructure or any unsolicited
            disclosures made by any user on the Website. If you suspect any misuse
            or loss or unauthorised access to your data or information, please let
            us know immediately by contacting us via e-mail at
            <b> admin@wyngmate.com</b>.
          </p>
          <p>
            When You register with Us, Your account is protected through login
            information, including a username and a password, which is known only
            to You. Therefore, You should not provide Your login and other
            Personal Information to anyone whosoever. Any breach thereof shall
            constitute a violation of this Policy and may result in closure of
            Your account in certain cases. If You become aware of or reasonably
            suspect any breach of security, including compromise of Your login
            information, it is Your responsibility to notify Us immediately.
          </p>
          <p>
            We have a policy of not sharing any personally identifiable
            information with anyone other than the entities specifically
            authorized by Us. We conduct periodic analysis and survey of the
            traffic to the Website for research purposes. We may also use
            cumulative non-personal information for auditing and analysis purposes
            to improve the Website.
          </p>
          <p>
            The information and data collected and processed on the Website in
            accordance with this Policy is hosted on servers located in India. We
            take steps to ensure that such information or data We collect is
            processed in accordance with this Policy and applicable laws.
          </p>
          <h4>8. Your Rights</h4>
          <p>
            You have the right to request a copy of Your information or data that
            We hold at any point in time. Upon Your request, We shall provide the
            same to You in a structured format. You shall also have the right to
            request for correction or deletion of any information or data. You
            have the right to ask Us towards restricting or limiting the way We
            use Your information or data. You may at any time withdraw Your
            consent to Us from processing Your information or data, however, any
            such withdrawal will not affect any information or data that has
            already been.
          </p>
          <p>
            processed. We may not adhere to any of Your request towards the
            aforementioned correction, deletion, restriction or limitation where
            We are required to comply with any applicable law or legal
            requirement.
          </p>
          <h4>9. Changes to this Policy</h4>
          <p>
            By accessing the Website, the User consents to the collection and use
            of the previously mentioned information on the Website. We may
            periodically change this Policy without notice, and the User is
            responsible for checking the policy periodically for revisions. All
            amended terms become effective upon Our posting to the Website, and
            any use of the Website after such revisions have been posted signifies
            the consent of the User to the changes.
          </p>
          <h4>10. Data retention and account deletion</h4>
          <p>
            We retain Your information and data for as long as necessary to
            provide You the access to, and use of, the Website. Such information
            and data shall also be retained for other essential purposes such as
            complying with applicable laws, and other legal requirement, and also
            in cases of resolving disputes (if any). Accordingly, the actual
            retention period may vary significantly for different information/data
            types and purposes.
          </p>
          <p>
            Even if We delete Your data or information, it may persist on backup
            or archival media for audit, legal, tax or regulatory purposes.
          </p>
          <p>
            Without prejudice to the foregoing, You have the option to terminate
            Your account, wherever applicable, by visiting the relevant section on
            the Website. We will, upon Your request, remove all of Your public
            information and posts, if any, on such termination and shall
            dissociate them from Your account so that it is seen anonymous and not
            attributed to Your identity. However, We may retain certain
            information about You for the purposes authorized under this Policy
            and Terms of Use unless prohibited by law. The information is retained
            to prevent, investigate, or identify possible wrongdoing in connection
            with the Website or to comply with legal obligations.
          </p>
          <h4>11. Third-party Links</h4>
          <p>
            The Website may provide web-links to any other website(s), where We do
            not have any control and shall not be responsible towards the content
            and conduct of such other website(s). You are advised to use the
            privacy statement and terms of such other website(s) before visiting.
          </p>
          <h4>12. Limitation of Liability</h4>
          <p>
            We confirm that this Privacy Policy is only a description of its
            operation regarding user information. This Privacy Policy is not
            intended to create any legal rights in Your favor or in favor of any
            other person. Subject to other provisions in this Policy, the
            liability of Company shall be limited to the removal of information
            from the system of the Website and the removal of other personally
            identifiable elements. Notwithstanding anything to the contrary
            contained in this Policy and elsewhere, the aggregate liability of
            Company for all claims for damages under the provisions on the
            Website, including claims concerning the violation of
          </p>
          <p>
            this Policy, shall be governed by the provisions under the Terms. Your
            continued use of the Website constitutes Your agreement to this
            Privacy Policy and any amendments thereof.
          </p>
          <h4>13. Applicable Laws</h4>
          <p>
            This Privacy Policy and collection and processing of any data shall at
            all times be governed by the applicable data privacy and protection
            laws of India as may apply to the Company in the jurisdictions from
            where Website is offered. The provisions with respect to governing law
            and dispute resolution as prescribed in the Terms shall apply mutatis
            mutandis to this Privacy Policy.
          </p>
          <h4>14. Grievance Officer</h4>
          <p>
            All communications relating to personal data may be sent to the
            Company at <b>admin@wyngmate.com</b>.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Privacy;
