import React from 'react';
import styles from "./styles.module.css";

const Card = ({ icon, title, text }) => {
  return (
    <div className="col-12 col-lg-4 py-3">
      <div className={`card ${styles.cardBox}`}>
        <div className={`card-body ${styles.cardBodyOuter}`}>
          <div className={styles.imageDiv}>
            <img src={icon} alt="" />
          </div>
          <div className={styles.textDiv}>
            <span className={styles.cardTitle}>{title}</span>
            <div className="card-text">{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
