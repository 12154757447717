import React, { useState, useContext, useEffect } from 'react';
import styles from "./styles.module.css";
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, Grid } from '@mui/material';
import { SEND_ORDER_DETAILS, SEND_ORDER_DETAILS_PAID } from '../../constants/apiEndpoints';
import apiService from '../../service/backendApiService';
import { showErrorMessage, showToastMessage } from '../../utils/common-utils';
import useRazorpay from "react-razorpay";
import { DataContext } from '../../context/DataProvider';
import Navbar from '../Navbar/NavBar';
import Footer from '../Footer/Footer';
import axios from 'axios';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GreaterIcon from "./images/greater-icon.png";
import PaymentSuccess from './PaymentSuccess';
import ReactGA from "react-ga4";

const PaymentPage = () => {

  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Web Payment Page" });

  const navigate = useNavigate();
  const location = useLocation();
  const fixedText = '.wyngmate.com';
  const { userInfo } = useContext(DataContext);
  const { planKey, planName, planPricingDetails, activePlanTab } = location.state;
  const [checked, setChecked] = useState(false);
  const [currentState, setCurrentState] = useState('billing');
  const [planFinalPrice, setPlanFinalPrice] = useState('');
  const [accountType, setAccountType] = useState('ORGANIZATION');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyDomain, setCompanyDomain] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [kfSubdomain, setKfSubdomain] = useState('');
  // const [selectedLogo, setSelectedLogo] = useState(null);
  const [emailChecked, setEmailChecked] = useState(false);
  const [adimnEmailChecked, setAdimnEmailChecked] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [adminEmailError, setAdminEmailError] = useState('');
  const [orderKey, setOrderKey] = useState('');
  const [orderId, setOrderId] = useState('');
  const [orderAmount, setOrderAmount] = useState('');
  const [Razorpay] = useRazorpay();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [planPriceCurrency, setPlanPriceCurrency] = useState('')

  useEffect(() => {
    const selectedPlanDetails = planPricingDetails?.find((plan) => plan.type === activePlanTab);
    setPlanFinalPrice(selectedPlanDetails?.price?.finalPrice);
    setPlanPriceCurrency(selectedPlanDetails?.price?.currency);
  }, [planPricingDetails, activePlanTab]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidAdminEmail = (adminEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(adminEmail);
  }

  const onChangeTermsCheckbox = () => {
    setChecked(!checked);
  }

  const handleOrganizationToggleChange = (event) => {
    if (event.target.value === 'ORGANIZATION') {
      setAccountType('ORGANIZATION');
    } else {
      setAccountType('PERSONAL');
    }
  }
  const onChangeEmailcheckedBox = () => {
    setEmailChecked(!emailChecked)
    if (accountType === 'PERSONAL') {
      setEmail(emailChecked ? '' : userInfo.email);
    }
  }

  const onChangeAdminEmailcheckedBox = () => {
    setAdimnEmailChecked(!adimnEmailChecked)
    setAdminEmail(adimnEmailChecked ? '' : userInfo.email);
  }

  // const handleLogoUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setSelectedLogo(file);
  //   } else {
  //     setSelectedLogo(null);
  //   }
  // };

  const onChangePostalCode = async (e) => {
    const code = e.target.value;
    const newCode = code.replace(/\D/g, '');
    setPostalCode(newCode);
    setPostalCodeError('');
    if (newCode.length === 6) {
      try {
        const response = await axios.get(`https://api.postalpincode.in/pincode/${newCode}`);
        if (response?.data?.[0]?.Status === 'Success') {
          const postOfficeData = response?.data?.[0]?.PostOffice
          setCity(postOfficeData?.[0]?.District);
          setState(postOfficeData?.[0]?.State);
          setCountry(postOfficeData?.[0]?.Country)
          setPostalCodeError(response?.data?.Message);
        }
        else {
          setPostalCodeError(response?.data?.[0]?.Message);
          setCity('');
          setState('');
          setCountry('');
        }
      } catch (e) {
        showErrorMessage(e);
      }
    }
  }


  const onOrderCreateDeatils = async (e) => {
    let isValid = true;
    if (accountType === "PERSONAL") {
      if (!isValidEmail(email)) {
        setEmailError('Invalid email format');
        isValid = false;
      }
    } else {
      if (!isValidAdminEmail(adminEmail)) {
        setAdminEmailError('Invalid email format');
        isValid = false;
      }
    }
    if (isValid && checked) {
      let payload = {
        admin_id: userInfo.userId,
        plan_key: planKey,
        plan_price: planFinalPrice,
        currency: planPriceCurrency,
        metadata: {
          plan_type: activePlanTab,
          account_type: accountType,
          address: addressLine1 + ' ' + addressLine2,
          city: city,
          state: state,
          country: country,
          pincode: postalCode,
        }
      };

      if (accountType === 'ORGANIZATION') {
        payload.metadata.companySubDomainEmail = companyDomain;
        payload.metadata.company_name = companyName;
        payload.metadata.admin_email = adminEmail;
        payload.metadata.kfSubDomain = kfSubdomain + fixedText;
        // payload.metadata.companyLogo = selectedLogo;
      } else {
        payload.metadata.email = email;
        payload.metadata.fullName = fullName;
      }
      await apiService.post(process.env.REACT_APP_ADMIN_SERVICE_URL + SEND_ORDER_DETAILS, payload)
        .then((response) => {
          if (response && response.status === 200) {
            setOrderKey(response?.data?.data?.key)
            setOrderId(response?.data?.data?.orderId)
            setOrderAmount(response?.data?.data?.amount)
            showToastMessage(response?.data?.message);
            setCurrentState('payment');
          }
        })
        .catch((e) => {
          showErrorMessage(e);
        });
    }
  }

  const handleMakePayment = async () => {
    const options = {
      key: orderKey,
      amount: orderAmount,
      currency: "INR",
      order_id: orderId,
      handler: function (response) {
        console.log(response)
        setPaymentSuccess(true);
      },
      prefill: {
        email: email,
      },
      theme: {
        color: "#3399cc",
      },
    };
    try {
      const rzp1 = new Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error('Error initializing Razorpay:', error);
    }
  };

  const handleZeroPayment = async () => {
    let payload = {
      admin_id: userInfo.userId,
      plan_key: planKey,
      plan_price: planFinalPrice,
      currency: planPriceCurrency,
      metadata: {
        plan_type: activePlanTab,
        account_type: accountType,
        address: addressLine1 + ' ' + addressLine2,
        city: city,
        state: state,
        country: country,
        pincode: postalCode,
      }
    };

    if (accountType === 'ORGANIZATION') {
      payload.metadata.companySubDomainEmail = companyDomain;
      payload.metadata.company_name = companyName;
      payload.metadata.admin_email = adminEmail;
      payload.metadata.kfSubDomain = kfSubdomain + fixedText;
      // payload.metadata.companyLogo = selectedLogo;
    } else {
      payload.metadata.email = email;
      payload.metadata.fullName = fullName;
    }
    await apiService.post(
      process.env.REACT_APP_ADMIN_SERVICE_URL + SEND_ORDER_DETAILS_PAID + "/" + orderId,
      payload
    )
      .then((response) => {
        if (response && response.status === 200) {
          setOrderKey(response?.data?.data?.key)
          setOrderId(response?.data?.data?.orderId)
          setOrderAmount(response?.data?.data?.amount)
          showToastMessage(response?.data?.message);
          setPaymentSuccess(true);
        }
      })
      .catch((e) => {
        showErrorMessage(e);
      });
  }

  return (
    <div>
      <Navbar />
      {paymentSuccess ?
        <PaymentSuccess accountType={accountType} />
        :
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <div className={styles.topSection}>
                <div className={styles.allPlanText} onClick={() => navigate('/pricing')} >
                  All plans
                </div>
                <div className={styles.headingDiv}>
                  <div className={styles.billingDiv}>
                    <img src={GreaterIcon} alt="greaterIcon" />
                    <p onClick={() => setCurrentState('billing')}>Billing information</p>
                  </div>
                  <div className={currentState === "billing" ? styles.paymentHidden : styles.paymentDiv}>
                    <img src={GreaterIcon} alt="greaterIcon" />
                    <p>Payment</p>
                  </div>
                </div>
              </div>
              {currentState === "billing" && (
                <div className={styles.deatilsContainer}>
                  <h4 className={styles.orderSummary}>Billing Information</h4>
                  <p className={styles.formLabel}>This will be displayed on all your invoices and billing communication.</p>
                  <p className={styles.toggleText}>Select the option that best fits your purchase: Organization or Personal use for WyngMate.</p>
                  <div className={styles.radioContainer}>
                    <div className={styles.radioButtonDiv}>
                      <input
                        type="radio"
                        id="organization"
                        value="ORGANIZATION"
                        checked={accountType === "ORGANIZATION"}
                        onChange={handleOrganizationToggleChange}
                      />
                      <label className={styles.radioLabal} htmlFor="organization">COMPANY</label>
                    </div>
                    <div className={styles.radioButtonDiv}>
                      <input
                        type="radio"
                        id="personal"
                        value="PERSONAL"
                        checked={accountType === "PERSONAL"}
                        onChange={handleOrganizationToggleChange}
                      />
                      <label className={styles.radioLabal} htmlFor="personal">INDIVIDUAL</label>
                    </div>
                  </div>

                  <Grid container spacing={2}>
                    {
                      accountType === "PERSONAL" && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <label className={styles.formLabel}>Full name<sup className={styles.mandatory}>*</sup></label>
                            <TextField
                              placeholder="Enter Full name"
                              size="small"
                              className={styles.textField}
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={styles.adminEmailCheckboxDiv}>
                              <label className={styles.formLabel}>Email<sup className={styles.mandatory}>*</sup></label>
                              <div className={styles.checkboxEmail}>
                                <input
                                  type="checkbox"
                                  checked={emailChecked}
                                  onChange={onChangeEmailcheckedBox}
                                />
                                <p className={styles.hoverText}>Same as logged in user</p>
                              </div>
                            </div>
                            <TextField
                              size="small"
                              className={styles.textField}
                              placeholder="Enter email"
                              value={email}
                              onChange={(e) => { setEmail(e.target.value); setEmailError('') }}
                            />
                            {emailError && <p className={styles.errorText}>{emailError}</p>}
                          </Grid>
                        </>
                      )
                    }
                    {accountType === "ORGANIZATION" && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <label className={styles.formLabel}>Company name<sup className={styles.mandatory}>*</sup></label>
                          <TextField
                            placeholder="Enter company name"
                            size="small"
                            className={styles.textField}
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={styles.adminEmailCheckboxDiv}>
                            <label className={styles.formLabel}>Company email domain<sup className={styles.mandatory}>*</sup></label>
                            <div className={styles.checkboxEmail}>
                              <InfoOutlinedIcon className={styles.infoIcon} />
                              <p className={styles.hoverText}>If email is test.user@xyz.com
                                then email domain will be xyz.com</p>
                            </div>
                          </div>
                          <TextField
                            size="small"
                            className={styles.textField}
                            placeholder="Enter company email"
                            value={companyDomain}
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(/\s/g, '');
                              setCompanyDomain(inputValue);

                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={styles.adminEmailCheckboxDiv}>
                            <label className={styles.formLabel}>WyngMate admin email<sup className={styles.mandatory}>*</sup></label>
                            <div className={styles.checkboxEmail}>
                              <input
                                type="checkbox"
                                checked={adimnEmailChecked}
                                onChange={onChangeAdminEmailcheckedBox}
                              />
                              <p className={styles.hoverText}>Same as logged in user</p>
                            </div>
                          </div>
                          <TextField
                            placeholder="Enter email"
                            size="small"
                            className={styles.textField}
                            value={adminEmail}
                            onChange={(e) => { setAdminEmail(e.target.value); setAdminEmailError('') }}
                          />
                          {adminEmailError && <p className={styles.errorText}>{adminEmailError}</p>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <label className={styles.formLabel}>WyngMate subdomain<sup className={styles.mandatory}>*</sup></label>
                          <div className={styles.subDomainInput}>
                            <TextField
                              placeholder="Enter subdomain"
                              size="small"
                              className={styles.textFieldSubDomain}
                              value={kfSubdomain}
                              onChange={(e) => {
                                const inputValue = e.target.value.replace(/\s/g, '');
                                setKfSubdomain(inputValue);

                              }}
                            />
                            {kfSubdomain && <p className={styles.subdomainText}>{fixedText}</p>}
                          </div>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                          <label className={styles.formLabel}>Upload logo</label>
                          <input
                            type="file"
                            onChange={handleLogoUpload}
                            className={styles.logoInput}
                            accept="image/*"
                          />
                        </Grid> */}
                      </>
                    )}
                    <Grid item xs={12} sm={6}>
                      <label className={styles.formLabel}>Address line 1<sup className={styles.mandatory}>*</sup></label>
                      <TextField
                        size="small"
                        className={styles.textField}
                        placeholder="Enter address line 1"
                        value={addressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label className={styles.formLabel}>Address line 2</label>
                      <TextField
                        size="small"
                        className={styles.textField}
                        placeholder="Enter address line 2"
                        value={addressLine2}
                        onChange={(e) => setAddressLine2(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label className={styles.formLabel}>Postal code<sup className={styles.mandatory}>*</sup></label>
                      <TextField
                        size="small"
                        className={styles.textField}
                        placeholder="Enter zip/postal code"
                        value={postalCode}
                        onChange={onChangePostalCode}
                        inputProps={{
                          maxLength: 6,
                          pattern: '[0-9]*',
                          inputMode: 'numeric',
                        }}
                      />
                      {postalCodeError && <p className={styles.errorText}>{postalCodeError}</p>}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label className={styles.formLabel}>City<sup className={styles.mandatory}>*</sup></label>
                      <TextField
                        size="small"
                        className={styles.textField}
                        placeholder="Enter city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label className={styles.formLabel}>State<sup className={styles.mandatory}>*</sup></label>
                      <TextField
                        size="small"
                        className={styles.textField}
                        placeholder="Enter state"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label className={styles.formLabel}>Country<sup className={styles.mandatory}>*</sup></label>
                      <TextField
                        size="small"
                        className={styles.textField}
                        placeholder="Enter Country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <div className={styles.buttonContainerSubscription}>
                    <span className={styles.checkboxText}>
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={onChangeTermsCheckbox}
                      />
                      <label type="optionSelector" >I agree to WyngMate's privacy policy and terms.</label>
                    </span>
                    <Button
                      disabled={
                        !checked || !addressLine1 || !city || !postalCode || !state || !country ||
                        (accountType === "ORGANIZATION" &&
                          (!companyDomain || !adminEmail || !companyName || !kfSubdomain)
                        )
                        || (accountType === "PERSONAL" && !fullName)
                      }
                      onClick={onOrderCreateDeatils}>
                      Proceed
                    </Button>
                  </div>
                </div>
              )}
              {currentState === "payment" && (
                <div className={styles.deatilsContainer}>
                  <p className={styles.orderSummary}>Order summary</p>
                  {accountType === "PERSONAL" ?
                    <div className={styles.orderDetails}>
                      <p className={styles.orderSummaryText}><span>Email :</span>  {email}</p>
                      <p className={styles.orderSummaryText}><span>Address :</span>  {`${addressLine1}, ${addressLine2} ${city} ${state} ${country} ${postalCode}`}</p>
                      <p className={styles.orderSummaryText}><span>Plan type :</span> {planName}{`(${activePlanTab})`}</p>
                    </div>
                    :
                    <div className={styles.orderDetails}>
                      <p className={styles.orderSummaryText}><span>Company name :</span> {companyName}</p>
                      <p className={styles.orderSummaryText}><span>Company email domain :</span> {companyDomain}</p>
                      <p className={styles.orderSummaryText}><span>WyngMate admin email :</span> {adminEmail}</p>
                      <p className={styles.orderSummaryText}><span>WyngMate subdomain :</span> {kfSubdomain + fixedText}</p>
                      <p className={styles.orderSummaryText}><span>Address :</span> {`${addressLine1}, ${addressLine2} ${city} ${state} ${country} ${postalCode}`}</p>
                      <p className={styles.orderSummaryText}><span>Plan type :</span> {planName}{`(${activePlanTab})`}</p>
                    </div>
                  }
                  <div>
                    <p className={styles.subtotal}>Subtotal <span>{planPriceCurrency === "USD" ? '$' : '₹'}{planFinalPrice}</span></p>
                    <p className={styles.subtotal}>Total <span>{planPriceCurrency === "USD" ? '$' : '₹'}{planFinalPrice}</span></p>
                  </div>
                  <div className={styles.buttonContainerSubscription}>
                    <div />
                    {planFinalPrice > 0 ?
                      <Button onClick={handleMakePayment}> Make payment </Button>
                      :
                      <Button onClick={handleZeroPayment}> Finish </Button>
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      <div className={styles.footerDivPayment}>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentPage;
