import React, { useEffect, useState } from 'react';
import styles from "./styles.module.css";
import { Row, Col, Card, Button } from 'react-bootstrap';
import RIGHT from "./images/right.svg";
import CROSS from "./images/cross-icon.svg";
import { GET_All_PRICING_DETAILS } from '../../constants/apiEndpoints';
import apiService from '../../service/backendApiService';
import { showErrorMessage } from '../../utils/common-utils';
import { useNavigate } from 'react-router-dom';
import LoadingSpnner from '../LoadingSpnner/LoadingSpnner';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const PlanTab = ({ activePlanTab }) => {

  const navigate = useNavigate();
  const [planData, setPlanData] = useState([])
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    getPricingDetails();
  }, [])

  const handlePlan = (planKey, planName, planPricingDetails) => {
    navigate("/payment-page", { state: { planKey, planName, planPricingDetails, activePlanTab } });
  };

  const freePlanRedirect = () =>{
    window.open(process.env.REACT_APP_KNOWLIOFORGE_WEBSITE, '_blank');
  }

  const getPricingDetails = async () => {
    setLoading(true)
    await apiService.get(process.env.REACT_APP_ADMIN_SERVICE_URL + GET_All_PRICING_DETAILS)
      .then((response) => {
        if (response && response.status === 200) {
          setPlanData(response?.data?.data)
          setLoading(false)
        }
      })
      .catch((e) => {
        showErrorMessage(e);
        setLoading(false)
      });
  }

  return (
    <Row>
      {loading ? <LoadingSpnner /> : (
        planData.map((plan) => (
          <Col xs={12} lg={4} key={plan?.planId} >
            <Card>
              <Card.Body className={styles.planBody}>
                <div className={styles.cardUpperSection}>
                  <Card.Title className={styles.planName}>{plan?.planMetaData?.name}</Card.Title>
                  <Card.Text>
                    {plan?.planKey === 'KF_BASIC'
                      ?
                      <div>
                        <strong>
                          Free Plan
                        </strong>
                      </div>
                      :
                      <>
                        <div>
                          <strong className={styles.actualDiv}>
                            <sup>{plan?.pricingDetails[0]?.price?.currency === "USD" ? '$' : '₹'}</sup>
                            {activePlanTab === 'MONTHLY'
                              ? plan?.pricingDetails[0]?.price?.actual
                              : plan?.pricingDetails[1]?.price?.actual}
                          </strong>&nbsp;
                          <strong>
                            <sup>{plan?.pricingDetails[0]?.price?.currency === "USD" ? '$' : '₹'}</sup>
                            {activePlanTab === 'MONTHLY'
                              ? plan?.pricingDetails[0]?.price?.finalPrice
                              : plan?.pricingDetails[1]?.price?.finalPrice}
                          </strong>
                          <span className={styles.perUserText}>
                          {activePlanTab === 'MONTHLY' ? 'Each user/month' : 'Each user/year'}
                          </span>
                        </div>
                        <p className={styles.offText}>you get {plan?.pricingDetails[0]?.price?.currency === "USD" ? '$' : '₹'}{activePlanTab === 'MONTHLY' ? plan?.pricingDetails[0]?.price?.discount : plan?.pricingDetails[1]?.price?.discount} Off</p>
                        {plan?.planKey === 'KF_ENTERPRISE' &&
                          <div className={styles.uptoUserText}>(upto 100 users)<InfoOutlinedIcon style={{margin:0}} className={styles.infoIcon} />
                          <div className={styles.hoverTextInfo}>
                            <h4>More than 100 users?</h4>
                            <p>Connect with our sales team for pricing and benefits info.</p>
                            <div className={styles.buttonWrapperClient}>
                              <Button onClick={()=> navigate('/contact')}>
                                Contact Sales
                              </Button>
                            </div>
                          </div>
                          </div>
                        }
                      </>
                    }
                  </Card.Text>
                  <div className={styles.planButton}>
                  {plan?.planKey === 'KF_BASIC' ?
                    <Button onClick={freePlanRedirect}>
                      Get Started Free
                    </Button>
                    :
                    <Button onClick={() => handlePlan(plan?.planKey, plan?.planMetaData?.name, plan?.pricingDetails)}>
                      Select Plan
                    </Button>
                  }
                  </div>
                </div>
                <Card.Text className={styles.planDetails}>
                  {plan.features.map((feature) => (
                    <div className={styles.plansText} key={feature?.featureId}>
                      {feature?.enabled === true ? <img src={RIGHT} alt="right" /> : <img src={CROSS} alt="right" />}
                      <div>
                        <p className={styles.featureName}>{feature?.metaInfo?.name}</p>
                        <p>{feature?.metaInfo?.description}</p>
                      </div>
                    </div>
                  ))}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))
      )}
    </Row>
  );
};

export default PlanTab;
