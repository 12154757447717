import React from 'react';
import LandingImg from "./images/landing-img.svg";

const LandingPage = () => {
  return (
    <div className='hero-section py-3 py-md-5'>
      <div className='container'>
        <div className='row' style={{ alignItems: 'center' }}>
          <div className='col-12 col-lg-6 pt-3 mb-5 mb-lg-0'>
            <h1 className="site-headline font-weight-bold mb-3">Build APIs together with WyngMate</h1>
            <div className="site-tagline mb-4">Build, test, and modify APIs like never before with the power of our community of developers. Join the revolution and be a part of shaping the future of technology. Sign up for free now and let's build something incredible together</div>
            <div className="cta-btns mb-lg-3">
              <a className="btn btn-primary me-2 mb-3" href={process.env.REACT_APP_KNOWLIOFORGE_WEBSITE} target="_blank" rel="noreferrer">Get Started Free</a>
            </div>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <img className="hero-figure mx-auto" src={LandingImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage;
