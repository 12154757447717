import React from 'react';
import styles from "./styles.module.css";
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/NavBar';
import ReactGA from "react-ga4";

const AboutUs = () => {

  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Web AboutUs Page" });

  return (
    <div>
      <Navbar />
      <div className="page-header theme-bg-dark py-5 text-center position-relative">
        <div className="theme-bg-shapes-right"></div>
        <div className="theme-bg-shapes-left"></div>
        <div className="container">
          <h1 className="page-heading single-col-max mx-auto">About Us</h1>
          <div className="page-intro single-col-max mx-auto">Welcome to WyngMate, powered by WyngMate!</div>
        </div>
      </div>
      <div className={`container ${styles.detailsContainer}`}>
        <p>At WyngMate, we're passionate about empowering developers and teams to streamline their API development process. WyngMate aims to revolutionize how APIs are built and utilized by simplifying API lifecycle management and enhancing collaboration.</p>
        <h4>Core Values</h4>
        <p>1. Innovation: We thrive on innovation, constantly seeking new ways to enhance the API development experience.</p>
        <p>2. Collaboration: Collaboration is at the heart of everything we do, fostering teamwork and synergy among developers and teams.</p>
        <p>3. Simplicity: We believe in simplifying complex processes, making API development accessible to all skill levels.</p>
        <p>4. Quality: We are committed to delivering high-quality solutions that meet the evolving needs of our users.</p>
        <h4>Vision</h4>
        <p>Our vision at WyngMate is to become the go-to platform for API development, providing developers with intuitive tools and resources to accelerate their projects and drive innovation in the tech industry.</p>
        <h4>Mission</h4>
        <p>Our mission is to empower developers and teams worldwide by offering a comprehensive suite of tools and resources that streamline the API development process, enhance collaboration, and ultimately, enable the creation of exceptional APIs—faster and more efficiently than ever before.</p>
      </div>
      <Footer />
    </div>
  )
}

export default AboutUs
