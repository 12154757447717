import React from 'react'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/NavBar';
import styles from "./styles.module.css";
import SuccessImg from "./images/success.png";

const PaymentSuccess = ({ accountType }) => {

  return (
    <div>
      <Navbar />
      <div className={styles.successPage}>
        <div className={styles.mainDiv}>
          {accountType === 'PERSONAL' ?
            <div>
              <h3>Success!</h3>
              <img src={SuccessImg} alt="successImg" />
              <h4>Thanks for subscribing WyngMate plan!</h4>
              <p>Begin exploring our app by clicking the link below.</p>
              <a href={process.env.REACT_APP_KNOWLIOFORGE_WEBSITE} target="_blank" rel="noreferrer">Go to WyngMate app</a>
            </div>
            :
            <div>
              <h3>Success!</h3>
              <img src={SuccessImg} alt="successImg" />
              <h4>Thanks for subscribing WyngMate plan!</h4>
              <p>Your account activation will be in progress you'll receive an email confirming your activation.</p>
            </div>
          }
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PaymentSuccess;
