import React from 'react';
import Card from './Card';
import IntroImg from "./images/introduction-icon.png";
import AccountImg from "./images/account-icon.png";
import PlansImg from "./images/plans-icon.png";
import OrganisingImg from "./images/organising-icon.png";
import CollectionImg from "./images/collection-icon.png";
import ApiImg from "./images/api-icon.png";
import RequestImg from "./images/request-icon.png";
import ShareImg from "./images/share-icon.png";
import ExportImg from "./images/export-icon.png";
import EnvironmentImg from "./images/environment-icon.png";
import HistoryImg from "./images/history-icon.png";
import NotificationImg from "./images/notification-icon.png";

const CardsContainer = () => {

  const cardData = [
    {
      id: 1,
      icon: IntroImg,
      title: 'Introduction',
      text: 'WyngMate, a truly innovative tool for developers. I always believed that the best technology should be both powerful and accessible, and it seems like WyngMate has achieved just that.',
    },
    {
      id: 2,
      icon: AccountImg,
      title: 'Accounts',
      text: 'WyngMate seems to offer two options for user authentication during the login process: OTP (One-Time Password) and Google login.',
    },
    {
      id: 3,
      icon: PlansImg,
      title: 'Plans',
      text: 'WyngMate typically offers two types of subscription plans: monthly and yearly. you can checkout our pricing section.',
    },
    {
      id: 4,
      icon: OrganisingImg,
      title: 'Organising WyngMate',
      text: 'With WyngMate, you can easily manage your collections, ensuring that your APIs are well-structured and easy to navigate.',
    },
    {
      id: 5,
      icon: CollectionImg,
      title: 'Organising collection',
      text: 'you to organize your APIs into collections, making it easy to manage and maintain your work.',
    },
    {
      id: 6,
      icon: ApiImg,
      title: 'Design APIs',
      text: 'Design and customize your APIs to meet your specific needs. This is a crucial step in the API development process, as it s where you determine the functionality and behavior of your APIs.',
    },
    {
      id: 7,
      icon: RequestImg,
      title: 'Mocking request',
      text: 'You can test and debug your APIs by mocking requests and responses.',
    },
    {
      id: 8,
      icon: ShareImg,
      title: 'Collection sharing',
      text: 'You can share your APIs with others, making it easy to collaborate and work together on API development projects.',
    },
    {
      id: 9,
      icon: ExportImg,
      title: 'Import export',
      text: 'You can import and export your APIs, making it easy to transfer your work between different environments or share it with others.',
    },
    {
      id: 10,
      icon: EnvironmentImg,
      title: 'Managing Environment',
      text: 'You can manage your API development environments, ensuring that your APIs are tested and deployed in the right environments.',
    },
    {
      id: 11,
      icon: HistoryImg,
      title: 'Managing History',
      text: 'You can view and manage the history of your API development projects, making it easy to track changes and revert to previous versions if necessary.',
    },
    {
      id: 12,
      icon: NotificationImg,
      title: 'Managing Notification',
      text: 'You can manage your API development notifications, ensuring that you re always up-to-date on the status of your projects.',
    },
  ];


  return (
    <div className="page-content py-5">
      <div className="container">
        <h3 className="mb-3 text-center font-weight-bold section-title">Features</h3>
        <div className="mb-5 text-center section-intro">WyngMate features include API testing, documentation generation, monitoring, mock request.</div>
        <div className="docs-overview">
          <div className="row">
            {cardData.map((card) => (
              <Card
                key={card.id}
                icon={card.icon}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsContainer;
