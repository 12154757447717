import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from "./styles.module.css";
import { Button, TextField, Grid } from "@mui/material";
import Logo from './images/logo.png';
import GoogleLogo from "./images/google-logo.png";
import { useGoogleLogin } from "@react-oauth/google";
import { DataContext } from '../../context/DataProvider';
import apiService from "../../service/backendApiService";
import { GOOGLE_LOGIN_API, OTP_LOGIN_API, OTP_VALIDATE_API } from '../../constants/apiEndpoints';
import LoadingSpnner from '../LoadingSpnner/LoadingSpnner';
import { showErrorMessage, showToastMessage } from '../../utils/common-utils';
import Navbar from '../Navbar/NavBar';
import ReactGA from "react-ga4";

const Login = () => {

  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Web Login Page" });

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [loginOtpDisabled, setLoginOtpDisabled] = useState(true);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);

  const { setUserLoggedIn, setUserInfo } =
    useContext(DataContext);

  useEffect(() => {
    let timerId;

    if (resendDisabled) {
      timerId = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timerId);
            setResendDisabled(false);
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [resendDisabled]);

  useEffect(() => {
    if (isOtpSent) {
      inputRefs.current[0]?.focus();
    }
  }, [isOtpSent]);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      setLoading(true);
      let payload = {
        code: tokenResponse.code,
        source: "WEBSITE"
      };

      await apiService.post(
        process.env.REACT_APP_ADMIN_SERVICE_URL + GOOGLE_LOGIN_API,
        payload
      )
        .then(async (response) => {
          if (response && response.status === 200) {
            setUserInfo({
              name: response?.data?.name,
              email: response?.data?.email,
              userId: response?.data?.userId,
            });
            localStorage.setItem(
              "token",
              JSON.stringify(response?.data?.token)
            );
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                name: response?.data?.name,
                email: response?.data?.email,
                userId: response?.data?.userId,
              })
            );
            setUserLoggedIn(true);
            setLoading(false);
            return true;
          } else {
            showErrorMessage("Login Unsuccessfull");
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          showErrorMessage(e);
        });
    },
    onError: (errorResponse) => {
      showErrorMessage("Google login unsuccessfull");
    },
  });

  const startCountdown = () => {
    setResendDisabled(true);
    setCountdown(30);
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setIsOtpSent(false);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(enteredEmail)) {
      setEmailError("Please enter a valid email address");
      setLoginOtpDisabled(true);
    } else {
      setEmailError("");
      setLoginOtpDisabled(false);
    }
  };

  const handleOtpDigitChange = (index, value) => {
    const updatedOtpDigits = [...otpDigits];
    updatedOtpDigits[index] = value;
    setOtpDigits(updatedOtpDigits);

    const isAllDigitsEntered = updatedOtpDigits.every((digit) => digit !== "");
    setLoginDisabled(!isAllDigitsEntered);
    setOtpError("");

    if (value === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (value !== "" && index < otpDigits.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleSendOtp = async () => {
    setIsOtpSent(true);
    setLoading(true);
    let payload = {
      contactType: "EMAIL",
      userContact: email,
      otpType: "LOGIN",
      source: "WEBSITE"
    };
    await apiService.post(
      process.env.REACT_APP_ADMIN_SERVICE_URL + OTP_LOGIN_API,
      payload
    )
      .then(async (response) => {
        if (response && response.status === 200) {
          startCountdown();
          setLoading(false);
          showToastMessage(response?.data?.message);
          setOtpDigits(["", "", "", ""]);
          setOtpError("");
        }
      })
      .catch((e) => {
        setLoading(false);
        showErrorMessage(e);
      });
  };

  const handleLogin = async () => {
    const otpInt = otpDigits.join("");
    setLoading(true);
    let payload = {
      contactType: "EMAIL",
      userContact: email,
      otpType: "LOGIN",
      otp: otpInt,
      source: "WEBSITE"
    };
    await apiService.post(
      process.env.REACT_APP_ADMIN_SERVICE_URL + OTP_VALIDATE_API,
      payload
    )
      .then(async (response) => {
        if (response && response.status === 200) {
          setUserInfo({
            name: response?.data?.name,
            email: response?.data?.email,
            userId: response?.data?.userId,
          });
          localStorage.setItem(
            "token",
            JSON.stringify(response?.data?.token)
          );
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              name: response?.data?.name,
              email: response?.data?.email,
              userId: response?.data?.userId,
            })
          );
          setUserLoggedIn(true);
          setLoading(false);
          showToastMessage(response?.data?.message);
        }
      })
      .catch((e) => {
        if (e.response?.status === 400) {
          setOtpError(e.response?.data?.message);
        }
        showErrorMessage(e);
        setLoading(false);
      });
  };

  return (
    <div className={`auth-section login-section py-5 ${styles.topMargin}`}>
      <Navbar />
      <div className="container">
        {/* <div className="site-logo text-center mb-4">
          <div className="navbar-brand me-0" >
            <img className="logo-icon me-2" src={Logo} alt="logo" />
            <span className="logo-text">WyngMate</span>
          </div>
        </div> */}
        <div className="auth-wrapper mx-auto shadow p-5 rounded">
          <h2 className="auth-heading text-center mb-5">Sign in WyngMate Account</h2>
          {loading ? <LoadingSpnner /> : (
            <>
              <div>
                <Button
                  className={styles.buttonWrapper}
                  onClick={googleLogin}
                >
                  <div className={styles.googleBtn}>
                    <div className={styles.googleIconWrapper}>
                      <img
                        className={styles.googleLogo}
                        src={GoogleLogo}
                        alt=""
                      />
                    </div>
                    <p className={styles.btnText}>Sign in with google</p>
                  </div>
                </Button>
              </div>
              <div className="divider my-5">
                <span className="or-text">OR</span>
              </div>
              <div>
                <label className={styles.formLabel}>Email</label>
                <TextField
                  placeholder="Email"
                  size="small"
                  className={styles.textField}
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <p className={styles.errorText}>{emailError}</p>
                )}
                <Grid container spacing={2}>
                  {!isOtpSent ? (
                    <Grid item xs={12}>
                      <Button
                        style={{ marginTop: "12px" }}
                        className={
                          loginOtpDisabled
                            ? `${styles.btnSignIn} ${styles.buttonDisabled}`
                            : styles.btnSignIn
                        }
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSendOtp}
                        disabled={loginOtpDisabled}
                      >
                        Login with OTP
                      </Button>
                    </Grid>
                  ) : (
                    <>
                      {isOtpSent && (
                        <div className={styles.otpDigitsContainer}>
                          <p className={styles.formLabel}>Enter OTP</p>
                          <Grid container spacing={2}>
                            {otpDigits.map((digit, index) => (
                              <Grid item xs={2} key={index}>
                                <TextField
                                  className={styles.otpTextField}
                                  size="small"
                                  value={digit}
                                  onChange={(e) =>
                                    handleOtpDigitChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  onKeyDown={(e) => {
                                    if (
                                      !/^[0-9]$/.test(e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  inputProps={{
                                    maxLength: 1,
                                    style: {
                                      textAlign: "center",
                                    },
                                  }}
                                  inputRef={(ref) =>
                                    (inputRefs.current[index] = ref)
                                  }
                                />
                              </Grid>
                            ))}
                          </Grid>
                          {otpError && (
                            <p className={styles.errorText}>{otpError}</p>
                          )}
                        </div>
                      )}
                      <Grid item xs={12}>
                        <Button
                          className={
                            loginDisabled || loginOtpDisabled
                              ? `${styles.btnSignIn} ${styles.buttonDisabled}`
                              : styles.btnSignIn
                          }
                          variant="contained"
                          fullWidth
                          onClick={handleLogin}
                          disabled={loginDisabled || loginOtpDisabled}
                        >
                          Login
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className={
                            resendDisabled
                              ? `${styles.reSendButton} ${styles.buttonDisabled}`
                              : styles.reSendButton
                          }
                          variant="outlined"
                          fullWidth
                          onClick={handleSendOtp}
                          disabled={resendDisabled}
                        >
                          Resend OTP {countdown === 0 ? "" : `${countdown}s`}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
